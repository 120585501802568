// Home Mobile

@media only screen and (min-width: 1600px) {
  .site-cover .home-background-container {
    top: -100px;
  }
  .home-background {
    transform: translateX(0) !important;
  }
}

@media only screen and (max-width: 1390px) {
  .site-cover .home-background-container {
    background-color: #ffffff;
    overflow: hidden;
    top: 0;
    svg, .home-background {
      width: 1400px;
    }
  }
}

@media only screen and (max-width: 1100px) {

  .home-section h2::before {
    width: 4px;
    border-radius: 2px;
  }

  #top {
    font-size: .8em;
  }

  #middle {
    font-size: .8em;
    padding: 20em 0;

    .site-content {
      padding: 0 10em;
    }
  }

  #bottom {
    font-size: .8em;
  }
}

@media only screen and (max-width: 1024px) {
  .site-cover {
    height: auto;
    min-height: 900px;
  }
}

@media only screen and (max-width: 990px) {
  .site-content h1 {
    text-shadow: 0 0 10px #ffffff,
    0 0 20px $light,
    0 0 30px $light,
    0 0 40px $light,
    0 0 50px $light,
    0 0 60px $light,
    0 0 70px $light;
  }
  .site-content {
    overflow: hidden;
    .pre-request-container .pre-request-content .main-btn {
      min-width: 226px;
    }
  }
}

@media only screen and (max-width: 860px) {

  .site-cover {
    height: auto;
    min-height: 900px;
    .site-content {
      padding: 5em 1rem;
      &.-home-align h1{
        margin-top: 1em;
      }
    }
    .home-background-container {
      padding-left: 50%;
      svg{
        display: none;
      }
    }
  }

  #top {
    font-size: .7em;
  }

  #middle {
    font-size: .7em;
  }

  #bottom {
    font-size: .7em;
  }
}

@media only screen and (max-width: 760px) {

  .site-cover {
    font-size: .8em;
    height: auto;
    min-height: inherit;
    text-align: center;
    .site-content {
      padding: 10em 1em 1em 1em;
      align-items: center;
      height: 100vh;
      &.-home-align{
        height: 90vh;
        p {
          text-shadow: 0 0 10px $light;
        }
      }
    }
    .home-background-container {
      padding-left: 0;
      .home-background {
        width: auto;
        height: 100vh;
      }
    }
    h1 {
      font-size: 4em;
      margin-top: 10px;
    }
  }

  .box-content {
    max-width: 100%;
    min-width: 100%;

    img.corner {
      display: none;
    }

    p {
      font-size: 1.4em;
    }

    &.-info {

      .box {
        padding: 4em 6em 6em 2em;

        img.casas {
          width: 11em;
        }
      }
    }

    &.-user {

      .box {
        padding: 4em 2em 3em;
      }
    }
  }

  #top {
    font-size: .9em;

    #institucional {
      margin-bottom: 4em;
    }

    .box-content.-user {
      display: block;
    }

    .box-content.-info {
      display: none;
    }
  }

  #middle {
    padding: 16em 0 11em;
    font-size: .9em;

    .site-content {
      padding: 0 2rem;
    }

    .home-link-item {
      display: flex;
      align-items: center;
      text-align: left;
      width: 100%;
      margin-bottom: 3em;

      p {
        font-size: 1.6em;
      }

      .icon {
        margin-bottom: 0;
        margin-right: 2em;
      }

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  .site-content .pre-request-container {
    justify-content: center;
    margin-right: 0;
    .pre-request-content {
      .title {
        text-shadow: none;
      }
      .description-text {
        color: $brandDarkBlue;
        text-shadow: none;
      }
      .main-btn {
        min-width: 182px;
      }
    }
  }

  #bottom {
    font-size: .9em;

    .box-content.-user {
      display: none;
    }

    .box-content.-info {
      display: block;
    }

    .features {
      padding: 6em 2em 0;
    }

    .feature-item {
      margin-bottom: 4em;

      img {
        margin-right: 1em;
      }
    }

    .feature-data {
      font-size: 1.3em;
    }
  }

  .-max-width{
    width: 100%;
  }
}

@media only screen and (max-width: 600px) {
  .site-cover .home-background-container {
    padding-left: 0;
  }
}

@media only screen and (max-width: 425px) {
  .site-cover {
    height: 100vh;
    .site-content.-home-align {
      h1 {
        font-size: 2.5em;
      }
      .main-btn {
        margin-top: 6px;
        transform: scale(.8);
      }
    }

  }
  path#ramas1 {
    animation: none;
  }
  path#ramas2, path#ramas3, path#ramas4, path#ramas5, path#ramas6, path#ramas7, path#ramas8, path#ramas9, path#ramas10, path#ramas11, path#ramas12, path#ramas13, path#ramas14, path#ramas15, path#ramas16, path#ramas17, path#ramas18 {
    display: none;
  }

}

