// Modals

.modal {
  border-radius: .4em;
  width: 840px;
  max-height: 80%;

  h4 {
    font-size: 1.8em;
    font-weight: 700;
    color: $brandDarkBlue;
    border-bottom: 1px solid $scaleGrey2;
    padding-bottom: .5em;
    margin-bottom: 1em;
  }

  .modal-content {
    padding: 3em 2em;
  }

  .modal-body {
    display: flex;

    .modal-main {
      flex-grow: 1;
      padding: 0;
    }

    .modal-aside {
      min-width: 215px;
      max-width: 215px;
      margin-left: 3em;
      margin-top: 1em;
    }
  }

  .contact-item {
    font-size: 1em;
    color: $scaleGrey3;
    display: flex;
    align-items: center;
    margin-bottom: 2em;

    .icon {
      width: 1em;
      font-size: 2em;
      margin-right: .5em;
    }

    p {
      font-size: 1em;

      span {
        font-weight: 700;
      }
    }

    .contact-number p span {
      font-size: 1.4em;
    }
  }

  .success-msg-item {
    p{
      font-size: 1.2em;
    }
  }

  .modal-footer {
    display: none;
  }

  .modal-close {
    color: $scaleGrey3;
    position: absolute;
    top: .5em;
    right: .5em;
    font-size: 1.5em;

    &:focus {
      background: transparent;
    }
  }
}

///////
//// Helper Modal
///////////////

.helper-modal {
  width: 500px;
  max-width: 95%;

  h4 {
    border-bottom: none;
    text-align: center;
    margin-top: 2em;
    padding: 0;
  }

  .modal-main {
    text-align: center;
  }
}

.helper-modal-btn {
  background-color: transparent;
  padding: 0;
  box-shadow: none;
  color: $siteBlue;
  position: absolute;
  top: .5em;
  right: 1em;

  &:focus {
    background: transparent;
  }
}

.helper-modal-btn-inline {
  background-color: transparent;
  padding: 0;
  box-shadow: none;
  color: $siteBlue;
  position: absolute;
  top: 0em;
  right: -1.5em;

  &:focus {
    background: transparent;
  }
}

.helper-modal-header {
  text-align: center;
  position: absolute;
  top: 2.5em;
  right: 0;
  left: 0;

  .icon {
    color: $siteBlue;
    font-size: 2em;
  }
}

.helper-icon {
  animation: beat 2.5s infinite;
}

@keyframes beat {
  0% {
    font-size: 1em;
  }
  50% {
    transform: scale(1.5);
  }
  100% {
    font-size: 1em;
  }
}

///////
//// Login Modal
///////////////

.login-modal {
  width: 400px;

  h4 {
    border-bottom: none;
    text-align: center;
    padding: 0;
  }

  .modal-content {
    padding: 4em 3em;
  }

  .submit-row {

    .main-btn {
      float: none;
      width: 100%;
    }
  }
}


///////
//// Request Status Modal
///////////////

.request-status-modal {
  .modal-content {
    flex-direction: column;
    h4 {
      border-bottom: none;
      margin-top: 1em;
      padding: 0;
    }

    .modal-body {
      display: block;
      .states-container {
        display: flex;
        flex-wrap: wrap;
        width: 100%;
        .state {
          margin: 0 1.5em 1em 0;
          position: relative;
          width: 5em;
          img {
            margin-bottom: .6em;
            width: 100%;
          }
          .icon-description {
            font-size: .9em;
            text-align: center;
            text-transform: uppercase;
          }
          .icon {
            color: $siteBlue;
            font-size: .9em;
            position: absolute;
            right: -1.1em;
            top: 3em;
          }
          &.-selected {
            .icon-description {
              color: $siteBlue;
              font-weight: 700;
            }
          }
          &.-disable {
            filter: grayscale(100%);
          }
        }
      }
    }

    .state-info {
      margin-top: 2em;
      .state-info-title {
        color: $siteBlue;
        font-size: 1.5em;
        margin-bottom: .8em;
        text-transform: uppercase;
      }
    }

  }
}

///////
//// Error Modal
///////////////
.error-modal {
  @extend .helper-modal;
  .modal-content {
    align-items: center;
    display: flex;
    flex-direction: column;
    max-width: 100%;
    width: auto;
    @extend .helper-modal;
    .modal-main p {
      text-align: center;
    }
    .main-btn {
      margin-top: 1em;
      max-width: 300px;
      width: 100%;
    }
  }
}

.error-modal-btn {
  @extend .helper-modal-btn;
  color: $brandYellow;
}

.error-modal-header {
  @extend .helper-modal-header;
  .icon {
    color: $brandYellow;
  }
}

///////
//// Payment method Modal
///////////////

.title-payment{
  text-align: center;
  font-size: 1.3em;
}

.payment-btn{
  color: white;
}

.payment-modal-body {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  text-align: center;

  h2 {
    font-size: 1.4em;
  }

  span {
    color: $siteBlue;
  }

  .main-btn {
    width: 30%;
    font-size: 1.4em;
    font-weight: 900;
    position: relative;
    color: white;
  }

  .contact-payment-item-box {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 15px;
    border: solid 1.5px;
    border-radius: 10px;
    width: 320px;
  }

  .item {
    margin-right: 15px;
    margin-bottom: 15px;
  }

  .installments {
    font-size: 1.6em;
    margin-bottom: 15px;
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;

    .first-installment-date {
      display: flex;
      justify-content: space-around;
      align-items: center;
      color: $scaleGrey3;

      .payment-date{
        margin-left: 5px;
      }
    }

    .installments-description{
      margin-top: 15px;
      width: 80%;

      h4 {
        margin-bottom: 5px;
        font-size: 1em;
        border: none;
      }

      li {
        list-style: none;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-self: center;

        .installment-data {
          display: flex;
          justify-content: space-between;
          align-items: center;
          width: 100%;
          margin-right: 10px;

          .installment-item {
            flex: 1 1 0;
          }

          .installment-type {
            display: flex;
            justify-content: center;
          }
        }
      }

      .cross {
        color: red;
      }

      .check {
        color: green;
      }

    }
  }
}

///////
//// Mercadopago redirect Modal
///////////////

.modal-mercadopago {
  h4 {
    text-align: center;
  }
}

.mercadopago-redirect-body {

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .info-mp {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .office-mp-info {
    min-width: 50%;

    .office-mp-row {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
    }
  }

  .mp-state-span {
    color: $siteBlue;
  }

  .mp-info-span {
    color: $brandDarkBlue;
  }

  p {
    font-size: 1.2em;
    margin: 5px 0px;
    text-align: center;
  }
}


///////
//// Mercadopago error in paymente Modal
///////////////

.modal-payment-error {

  .modal-body{
    height: 40vh;
  }

  .btn-back {
    font-weight: bold;
    font-size: 1.1em;
    border-radius: 1.2em;
  }

  .modal-payment-error-body {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    height: 100%;
  }

  .modal-content{
    h4 {
      display: none;
    }

    p {
      font-size: 1.5rem;
      text-align: center;
    }
  }

  .payment-error-header {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    h2 {
      font-weight: bold;
      color: $brandRed;
      font-size: 1.8rem;
    }
    h1 {
      font-weight: bold;
      font-size: 2rem;
      color: $dark;
    }
  }
}

.modal-confirmation {
   width: 500px;

  .modal-body{
    height: 20vh;
  }

  .btn-back {
    font-weight: bold;
    font-size: 1.1em;
    border-radius: 1.2em;
  }

  .modal-confirmation-body {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    height: 100%;
  }

  .modal-content{
    h4 {
      display: none;
    }

    p {
      font-size: 1.5rem;
      text-align: center;
    }
  }

  .modal-confirmation-header {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    h2 {
      font-weight: bold;
      color: $brandRed;
      font-size: 1.8rem;
    }
    h1 {
      font-weight: bold;
      font-size: 2rem;
      color: $dark;
    }
  }

  .modal-confirmation-buttons {
    margin-top: 20px;
    display: flex;
    gap: 12px;
  }
}