// Inmobiliarias Mobile

@media only screen and (max-width: 1100px) {

  section.select-box {
    font-size: .9em;
  }

  section.real-estate {
    font-size: .9em;

    .card-group .card .card-content, .card-group .card .card-reveal {
      padding: 24px 20px 30px 25px;
    }

    .card-group .card .card-image img {
      width: 110%;
    }
  }
}

@media only screen and (max-width: 860px) {

  .select-box .box-container .contact-info .contact-item {
    display: flex;
    margin: 0 10px 20px;
  }

  .card-group .card {
    margin: 20px 1.5%;
    width: 47%;
  }
}

@media only screen and (max-width: 760px) {

  .main-office .contact-info .contact-item p {
    font-size: 1.1em;
  }

  .card-group .card .card-content .card-title {
    font-size: 1.3em;
  }

  .title {
    font-size: 2em;
  }

  .subtitle {
    font-size: 1.3em;
  }

  .select-box .row {
    padding: 2em 1em 0;
  }

  .real-estate .card {
    height: 115px;
    margin: 7px 0;
    width: 100%;

    .card-image {
      height: 0;
      padding-top: 0;

      img {
        display: none;
      }
    }

    .card-content, .card-reveal {
      padding: 12px 20px 15px 25px;

      .card-title {
        font-size: 1.3em;
      }
    }

    .card-reveal .contact-item {
      display: flex;

      &:nth-child(2) {
        display: none;
      }
    }
  }

}