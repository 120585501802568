///////
//// Solicitud de Garantía // MOBILE
/////////////////

.-mobile {
  & .request-nav-bar {
    position: fixed;
    width: 100%;
    z-index: 1;
  }

  & main {

    .form-nav-mobile {
      flex-direction: column;
      padding: inherit;
      position: fixed;

      .progress {
        background-color: $scaleGrey2;
        border-radius: 0;
        height: 8px;
        margin: 0;

        .determinate {
          background-color: $siteBlue;
          border-bottom-right-radius: 10px;
          border-top-right-radius: 10px;
        }
      }

      .progress-info-container {
        border-bottom: 2px solid $scaleGrey2;
        display: flex;
        justify-content: center;
        width: 100%;

        .progress-info {
          align-items: center;
          display: flex;
          font-size: 1.2em;
          justify-content: space-between;
          max-width: 450px;
          padding: 10px 25px;
          width: 100%;

          .progress-info-title {
            font-weight: 700;
          }

          .progress-amount {
            color: $siteBlue;
            font-size: 1.3em;
          }
        }
      }

      .item-container {
        padding: 10px 0 5px;
      }
    }

    .form-body-container {
      margin-top: 5em;

      .form-body .form-content {
        height: inherit;
        max-width: 450px;
        margin: 0 auto 175px;
        padding: 0 1.2rem;

        h2 {
          text-align: center;
        }

        .form-inputs {
          .row {
            margin-right: 0;
            margin-left: 0;
          }

        }
      }
    }

    .back-btn {
      font-size: 1.2em;
      margin: 1em 0 0 1.5em;
    }
  }
}

// for icons in the Form-nav-mobile
.-unselected {
  filter: grayscale(100%) brightness(110%);
}

@media only screen and (max-width: 852px) {
  .secondary-title {
    font-size: 2em;
  }
}

@media only screen and (max-width: 768px) {
  header.request-nav-bar {
    .site-logo {
      width: 100px;
      height: auto;
      line-height: 0;
      transition: width 0.3s ease 0s;
    }

    .request-user {
      button p {
        display: none;
      }
    }
  }

  .layout-request.begin {
    header .app-logo {
      width: 100px;
    }

    main {
      font-size: .9em;
      padding: 3em 2em;
      width: 100%;
    }

    .middle-bar {
      background-color: transparent;
      box-shadow: none;
    }

    .bank-account-details {
      width: 80%;
    }

    .select-payment-btn {
      width: 90%;
    }
  }

}

@media only screen and (max-width: 615px) {
  .pre-request-result .secondary-title .helper-modal-btn {
    position: inherit;
  }

  .pre-request-result .exclamation-text {
    width: 370px;

    .helper-modal-btn {
      top: 0;
      right: 9.5em;
    }
  }

  .mercadopago-container {
    .slider-nav {
      display: none;
    }
  }

  .mercado-pago-payment {
    flex-direction: column;
    width: 100%;
    // height: inherit;

    .nid-card {
      width: 100%;
      margin-bottom: 10px;

      .nid-card-image {
        height: 40px;
        width: 50%;

        .icon-user-nid {
          width: 40px;

          svg {
            height: 30px;
            width: 30px;
          }
        }
      }

      .nid-card-number {
        .nid-type-field {
          font-size: 1rem;
        }

        .nid-number-field {
          font-size: 1.2rem;
        }
      }
    }

    .rccs__card {
      width: 250px;
      height: 150px;
    }

    .rccs__name {
      display: none;
    }

    .nid-form {
      width: 100%;

      .credit-row {
        flex-direction: column;

        .select-nid-type {
          width: 95%;
        }

        .nid-number {
          width: 95%;
          margin: 0;
        }
      }
    }

    .mp-card-form {
      width: 90%;
      margin-top: 10px;
    }

    .installments-container {
      justify-content: center;

      .select-installments-container {
        // justify-content: flex-start;
        width: 80%;
        max-width: 300px;

        .react-select {
          width: 90%;
        }
      }
    }
  }
}


@media only screen and (max-width: 425px) {
  header.request-nav-bar {
    font-size: .9em;

    .site-logo {
      width: 80px;

    }

    .request-user .user-name {
      margin-right: .5em;
    }
  }

  .items-container .form-nav-item-mobile {
    width: 4.5em;
  }

  .secondary-title {
    font-size: 1.7em;
  }

  .pre-request-result .contact {
    margin-bottom: 1em;
    position: inherit;
    margin-top: 5em;

    .contact-items-group {
      flex-direction: column;
      margin-top: 1em;

      .contact-item {
        margin: 2em 0 0 0;

        &:first-child {
          margin-top: 0;
        }
      }
    }
  }
}

///////
//// Historial Solicitudes
/////////////////
@media only screen and (max-width: 768px) {

  .record-item {
    flex-wrap: wrap;
    padding: 1.5em;

    .code {
      width: 50%;
    }

    .state {
      width: 50%;
    }

    .address {
      margin-top: 1em;
      width: 100%;
    }
  }
}