// Lists Mobile

///////
//// Collapsible
///////////////

@media only screen and (max-width: 860px) {

  .collapsible-body {
    padding-left: 0;
    padding-right: 0;
  }
}