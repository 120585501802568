// Sections Mobile

@media only screen and (max-width: 1100px) {

  .row.input-field.paids-information .input-content p {
    margin-bottom: 0;
  }

  #calculadora {
    font-size: .8em;

    .paids-information .information-paids-box {
      padding: 20px 15px 10px;
    }
  }

  .info-paids-box {
    font-size: .8em;
  }

}

@media only screen and (max-width: 860px) {

  .section-content {
    align-items: center;
    flex-direction: column;
  }

  .section-box {
    width: 100%;
  }

  .section-aside {
    margin-left: 0;
    margin-top: 3em;
    min-width: 100%;
    max-width: 100%;
  }

  #calculadora {
    font-size: 1em;
    margin-bottom: 3em;

    .paids-information {
      flex-direction: column;

      .input-content {
        padding-right: 0;

        .input-field {
          padding-right: 0;
        }
      }

      .submit-content {
        height: 10em;
      }

      .calculator-control-result {
        width: 100%;
      }

      .information-paids-box {
        margin-bottom: 2em;
        width: 100%;
        height: 80%;
      }
    }
  }

  .info-paids-box {
    font-size: 1em;
    margin-left: 0;
    width: 43em;
  }

  .row .col.s12 {
    width: 100%;
  }

  .calculate-row-container {
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;

    .calculate-btn {
      width: 100%;
      margin: 0px 10px 0px 5px;
    }
  }

}

@media only screen and (max-width: 760px) {

  .section-box {
    padding: 3em 2em;
  }

  .section-content {
    padding-bottom: 6em;
  }

  #calculadora {
    background-color: transparent;
    padding: 0 1em;
  }

  .info-paids-box {
    margin-top: 2em;
    width: 100%;
  }
}

@media only screen and (max-width: 540px) {

  .installment-date {
    display: none;
  }

  .payment-modal-body {
    .main-btn {
      width: 50%;
    }
  }
}