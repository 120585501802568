@media only screen and (min-width: 768px) {
  .dashboard main .dashboard-col .dashboard-nav .helper-modal-container {
    display: none;
  }
}

@media only screen and (max-width: 768px) {
  .dashboard main {
    align-items: flex-start;
    padding: 10em 1em;
    .dashboard-col {
      margin: 0 1em;
      width: 60%;
      h1 {
        font-size: 2.8em;
      }
      .request-code .code {
        font-size: 2.7em;
      }
      &.-right {
        width: 40%;
        margin: 0 1em 0 0;
        max-width: 200px;
        .dashboard-progressbar {
          width: auto;
        }
        .completed-forms-message {
          flex-direction: column;
          .title{
            font-size: 1.4em;
            margin-bottom: 0;
            position: static;
          }
          .message{
            font-size: 1.1em;
          }
        }
        .assigned-office {
          display: none;
        }
      }
      .dashboard-nav {
        width: 90%;
        .helper-modal-container {
          align-items: center;
          display: flex;
          margin-top: 2em;
          padding: 1em .4em 1em;
          .helper-modal-btn {
            font-size: 1.4em;
            position: static;
          }
          .helper-modal-text {
            color: $siteBlue;
            font-size: 1.1em;
            margin-left: 1em;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 425px) {

  .dashboard main {
    align-items: flex-start;
    padding: 4em 1em;
    .dashboard-col {
      margin: 0 1em;
      width: 45%;
      h1 {
        font-size: 1.7em;
      }
      .request-code .code {
        font-size: 2em;
      }
      .request-state {
        align-items: flex-start;
        flex-direction: column;
        .state-btn {
          margin-left: 0;
          padding: 0 5px;
          text-align: left;
        }
      }
      .dashboard-nav {
        width: 75vw;
      }
    }

    .dashboard-col.-right {
      margin: 0 1em;
      width: 55%;
      .dashboard-progressbar {
        width: auto;
      }
      .completed-forms-message {
        .title{
          font-size: 1.2em;
        }
        .message{
          font-size: .9em;
          margin: 0;
        }
      }
    }

  }
}
