// Layout

html,
body {
  margin: 0;
  font-family: $site-font;
  color: $dark;
}

.site-content {
  width: 1300px;
  margin: 0 auto;
}

.loader-content {
  display: flex;
  flex-direction: column;
  height: 100vh;
  align-items: center;
  justify-content: center;
  .message {
    margin-top: 2em;
    text-align: center;
    padding: 0 2em;
  }
}

.loader-section-content{
  @extend .loader-content;
  height: auto;
  padding: 3em 0;
}

main.sections {
  min-height: 95vh;
  background-color: $scaleGrey1;
  padding-top: 10.5em;
  padding-bottom: 6em;
}

///////
//// Site Nav
/////////////////
header.main-header {
  background-color: initial;
  height: 100px;
  padding-top: 22px;
  position: fixed;
  width: 100%;
  z-index: 9999;

  &.scroll {
    padding-top: 0;
  }
}

nav.main-nav-bar {
  padding: 0 2em;
  background-color: transparent;
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0);
  display: flex;
  align-items: center;
  justify-content: space-between;
  transition: height .3s, background-color .3s;

  .site-logo {
    width: 153px;
    height: auto;
    line-height: 0;
    transition: width .3s;
  }
  .main-nav-item a {
    color: $brandDarkBlue;
  }

  &.scroll {
    height: 70px;
    z-index: 999;
    background-color: $light;
    box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.10);

    .site-logo {
      width: 130px;
    }

    .main-nav-item {
      height: 70px;
    }
  }
}

.main-nav-options {
  display: flex;
  align-items: center;
}

.icon-whatsapp-fixed {
  height: 55px;
  z-index: 5;
  position: fixed;
  bottom: 0;
  margin-bottom: 100px;
  margin-left: 15px;
}

.main-nav-item {
  display: flex;
  align-items: center;
  margin-left: 2em;
  padding: 0 .5em;
  font-size: 1.2em;
  color: $brandDarkBlue;
  height: 100px;
  box-shadow: inset 0 0 0 0 rgba(249, 123, 112, 1);
  transition: height, box-shadow .3s;

  &:hover {
    box-shadow: inset 0 2px 0 0 rgba(249, 123, 112, 1);
  }

  &:focus {
    background: transparent;
  }

  &.data-only {
    color: $brandRed;

    p {
      font-weight: 700;
    }

    &:hover {
      box-shadow: none;
    }
  }

  .icon-whatsapp-container {
    display: flex;
  }

  .icon {
    color: $brandRed;
    margin-right: .6em;
  }

  .icon-whatsapp{
    height: 35px;
    margin-bottom: -4px;
    margin-right: 10px;
    transform: translateY(-2px);
  }
}

.form-nav .collapsible-body {

  ul li button{
    background-color: transparent;
    width: auto;
  }
  button{
    width: 100%;
    overflow: hidden;
    max-height: 37px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: $siteBlue;
    .btn-spinner{
      transform: scale(0.2) translateY(-80px);
      filter: brightness(10);
    }
  }


}

.form-nav .nav-action {
    background-color: white;
    position: relative;
    z-index: 1;
    box-shadow: 0 5px 7px -9px rgba(0,0,0,0.3);
  }

.bm-overlay {
  top: 0;
}

.bm-menu-wrap {
  top: 20px;
}

///////
//// site Footer
///////////////

.main-footer-extended {
  display: flex;
  flex-direction: column;
}

.main-footer {
  color: $light;
  font-size: .8em;
  background-color: $brandRed;
  padding: 2em 2em 3em;
  display: flex;
  align-items: center;
  justify-content: space-between;

  a {
    color: $light;
  }

  p {
    margin: 0;

    span {
      font-weight: 700;
    }
  }

  .app-logo {
    width: 126px;
  }

  .company-info {
    display: flex;
    align-items: center;

    .info {
      border-left: 1px solid $light;
      padding-left: 1em;
      margin-left: 1em;
    }

    .data-fiscal {
      padding-left: 1em;
      margin-left: 1em;
    }

    .img-data-fiscal {
      width: 45px;
      height: 45px;
      margin-top: 5px;
      object-fit: contain;
    }

    .img-data-tad {
      margin-left: 0.5em;
      height: 45px;
      width: 45px;
      margin-top: 5px;
      object-fit: contain;
    }
  }

  .footer-options {
    display: flex;
    align-items: center;

    li {
      border-right: 1px solid $light;
      padding-right: 1.5em;
      margin-right: 1.5em;

      &:last-child {
        border-right: none;
        padding-right: 0;
        margin-right: 0;
      }
    }

    a {
      text-decoration: underline;

      &:hover {
        text-decoration: none;
      }
    }

    button {
      text-decoration: underline;
      padding: 0;

      &:hover {
        text-decoration: none;
      }

      &:focus {
        background: transparent;
      }
    }
  }

  .contact-links {
    display: flex;
    align-items: center;

    a {
      margin-left: 1em;
      font-size: 1.6em;
    }
  }
}

.legal-footer-extended {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;

  .legal-footer {
    width: 100%;
    display: flex;
    gap: 20px;
    justify-content: center;
    padding: 20px 30px 10px 30px;
    flex-wrap: wrap;

    color: #5B5B5B;
    font-family: Helvetica Neue, Helvetica, Arial, sans-serif;
    font-size: 85%;
    line-height: 1.25em;
    text-rendering: optimizeLegibility;
    word-spacing: normal;

    > div {
      display: flex;
      flex-direction: column;
      align-items: center;

      img{
        width: calc(min(250px, 80vw));
        height: auto;
      }
    }
  }

  .legal-foot-note {
    width: 100%;
    display: flex;
    justify-content: center;
    gap: 3em;
    margin-bottom: 20px;
    color: #211c18;
    padding-left: 10%;
    padding-right: 10%;

    .left-foot-note {
      flex-basis: 85%;
      display: flex;
      flex-direction: column;
      gap: 0.5em;
      font-size: 0.8em;
      justify-content: center;
    }

    .right-foot-note {
      flex-basis: 35%;
      display: flex;
      flex-direction: column;
      gap: 10px;
      font-size: 85%;
    }

  }
}
///////
//// Burger Menu
///////////////

/* Styling of overlay */
.bm-overlay {
  background: rgba(0, 0, 0, 0.3);
}

/* General sidebar styles */
.bm-menu {
  background: $brandRed;
  padding: 2.5em 2.5em 0 1em;
  font-size: 1em;
}

/* Wrapper for item list */
.bm-item-list {
  background: transparent;
  box-shadow: none;
}

/* Individual item */
.bm-item {
  padding: 0 .7em;
  color: $light;
  border-bottom: 1px solid $light;
  transition: padding-left .3s;
  font-size: inherit;

  &:hover {
    padding-left: 1.4em;
  }

  &:focus {
    outline: none;
  }
}

.bm-menu-wrap .menu-item-logout {
  color: $light;
  box-shadow: none;
  font-size: inherit;
  height: inherit;
  margin: inherit;
  width: 100%;
  @extend .bm-item;
  svg {
    display: none;
  }
}

/* Position and sizing of clickable cross button */
.bm-cross-button {
  height: 24px;
  width: 24px;
}

/* Color/shape of close button cross */
.bm-cross {
  background: $light;
}

.lala{
  background-color: red;
}