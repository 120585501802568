// Fonts

// --- load
@import url('https://fonts.googleapis.com/css?family=PT+Sans:400,400i,700,700i');
@import url('https://fonts.googleapis.com/icon?family=Material+Icons');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@500;600&display=swap');

$site-font: 'Montserrat', sans-serif;

h1, h2, h3, h4, p {
  margin: 0;
}

.title{
  color: $brandRed;
  font-size: 2.3em;
  font-weight: 700;
}

.secondary-title{
  color: $brandDarkBlue;
  font-size: 2.3em;
  font-weight: 700;
}

.subtitle{
  color: $brandRed;
  font-size: 1.8em;
  font-weight: 700;
}

.basic-text {
  font-size: 1.3em;
  margin-bottom: 1em;

  span {
    font-weight: 700;
  }

  a {
    color: $siteBlue;
    text-decoration: underline;

    &:hover {
      text-decoration: none;
    }
  }

  .text-link {
    font-size: 1em;
    padding: 0;
    background: transparent;
    box-shadow: none;
    color: $siteBlue;
    text-decoration: underline;
    text-transform: none;

    &:hover {
      text-decoration: none;
    }
  }

  div {
    display: inline-flex;
  }
}

.success{
  color: $siteBlue;
  font-size: 1em;
}

.row .error{
  text-align: center;
}

.error{
  color: $brandRed;
  font-size: 1em;
}

.warning-text {
  align-items: center;
  display: flex;
  font-size: .8em;
  font-style: italic;
  line-height: 1.4em;
  margin-bottom: 0;
}

.non-copy-text{
  user-select: none;
}