// Home

///////
//// Home Cover
///////////////

.site-cover {
  padding-top: 150px;
  height: 100vh;
  text-align: right;
  font-size: 1em;
  .-home-align{
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }
  .scroll-icon {
    animation: up-down 3s infinite;
    bottom: 10px;
    color: $siteBlue;
    font-size: 3em;
    left: 49%;
    opacity: .5;
    position: absolute;
  }
  @keyframes up-down {
    0% {
      transform: translateY(0);
    }
    50% {
      transform: translateY(10px);
    }
    100% {
      transform: translateY(0);
    }
  }

  h1 {
    font-size: 4.2em;
    color: $brandDarkBlue;
    font-weight: 700;
    margin-top: 2em;
    margin-bottom: .3em;

    span {
      color: $brandRed;
      text-transform: uppercase;
    }
  }

  p {
    font-size: 1.5em;
    span {
      color: $brandRed;
    }
  }

  .main-btn {
    font-size: 1.5em;
    margin-top: 2em;
  }
  .home-background-container {
    align-items: flex-start;
    display: flex;
    justify-content: center;
    overflow: hidden;
    position: absolute;
    right: 0;
    top: 0;
    width: 100%;
    z-index: -2;
    .home-background {
      width: 100%;
    }
  }

  .home-animation-container {
    align-items: flex-start;
    display: flex;
    justify-content: center;
    overflow: hidden;
    position: absolute;
    right: 0;
    top: 0;
    width: 100%;
    z-index: -2;
    .animation-image {
      width: 100%;
    }
  }
}

///////
//// Boxes
///////////////

.box-content {
  min-width: 33.3em;
  max-width: 33.3em;
  position: relative;
  margin-bottom: -3em;

  .box {
    background-color: $brandDarkBlue;
    border-radius: .7em;
    color: $light;
    box-shadow: -6px -6px 10px 0 rgba(0, 0, 0, 0.1);
    position: relative;
    z-index: 2;
  }

  &.-info {

    .box {
      padding: 4em 9em 6em 3em;
      height: 100%;

      p {
        margin-bottom: .5em;
      }

      img.casas {
        width: 13.3em;
        position: absolute;
        right: 20px;
        bottom: 25px;
      }

      .main-btn {
        margin-top: 2em;
      }
    }
  }

  &.-user {

    .box {
      position: relative;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      padding: 6em 3em 4em;

      h2 {
        width: 100%;
        margin-bottom: 2em;
      }

      .btn-list {
        width: 16.7em;

        .main-btn {
          width: 100%;
          margin-bottom: 2em;
        }
      }

      img.calculadora {
        height: 7em;
      }

      .main-btn.-box {
        position: absolute;
        right: 0;
        left: 0;
        bottom: -3.5em;
        font-size: 1.5em;
      }
    }
  }

  img.corner {
    width: 3.7em;
    height: 2.93em;
    position: absolute;
    z-index: 1;
    bottom: 1px;
    left: -3.3em;
  }

  &.-top-corner {
    margin-top: -3em;
    margin-bottom: 0;

    img.corner {
      transform: rotate(180deg);
      top: 1px;
      left: auto;
      right: -3.3em;
    }
  }
}

///////
//// Home Sections
///////////////

.home-section {
  background-color: $light;
  color: $brandDarkBlue;

  .site-content {
    display: flex;
    justify-content: space-between;
  }

  h2, h3 {
    font-weight: 700;
  }

  h2 {
    display: flex;
    align-items: stretch;

    &::before {
      content: '';
      width: 9px;
      border-radius: 3px;
      margin-right: .4em;
      background-color: $brandLightRed;
    }
  }

  .box-content h2 {
    font-size: 2em;
    margin-bottom: 1em;

    &::before {
      width: 6px;
      background-color: $light;
    }
  }
}

#top {
  font-size: 1em;
  padding-top: 6.6em;

  p {
    font-size: 1.5em;

    span {
      font-weight: 700;
    }
  }

  #institucional {
    margin-top: 3.3em;
    max-width: 30em;

    h2 {
      display: flex;
      gap: 0.18em;
      position: relative;
      font-size: 2.5em;
      margin-bottom: .7em;
      span {
        color: $brandRed;
      }
    }

    p {
      margin-bottom: 2em;
      span {
        color: $brandRed;
      }
    }

    .cuote {
      font-size: 1.7em;
      font-weight: 700;
      color: $brandLightRed;
    }
  }

  .box-content.-user {
    display: none;
  }
}

#middle {
  font-size: 1em;
  background-color: $scaleGrey1;
  padding: 16.6em 0;

  .site-content {
    padding: 0 6.6em;
  }


  .home-link-item-container {
    display: flex;
    flex-direction: column;
    align-items: center;

    p {
      font-size: 0.9em
    }
  }

  .home-link-item {
    width: 12.3em;
    text-align: center;
    color: $brandDarkBlue;
    text-decoration: underline;
    font-weight: 700;

    p {
      font-size: 1em;
    }

    .icon {
      color: $brandLightRed;
      background-color: $light;
      box-shadow: 12px 12px 20px 0 rgba(0, 0, 0, 0.07);
      width: 6.3em;
      height: 6.3em;
      border-radius: 50%;
      padding: 2em;
      margin-bottom: 1em;
      transition: border .3s;
      border: 0 solid $brandLightRed;
    }

    &:hover {
      text-decoration: none;

      .icon {
        box-shadow: none;
        border: 4px solid $brandLightRed;
      }
    }
  }
}

.pre-request-container {
  display: flex;
  justify-content: flex-end;
  margin-right: -1.5em;
  margin-top: 2em;
  .pre-request-content {
    background-color: rgba(255, 255, 255, 0.7);
    border-radius: .5em;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 1.5em;
    .title {
      font-size: 1.7em;
      font-style: italic;
      color: $brandLightRed;
      text-align: center;
      text-shadow: none;
    }
    .description-text {
      font-size: 1.3em;
      text-align: center;
    }
    .main-btn {
      min-width: 240px;
      margin-top: .5em;
    }
  }
}

#bottom {
  font-size: 1em;
  padding-bottom: 6.6em;

  .box-content.-info {
    display: none;
  }

  .features {
    flex-grow: 1;
    padding: 3.3em 0 0 6.6em;
  }

  .feature-item {
    display: flex;
    align-items: center;
    margin-bottom: 2em;

    img {
      width: 7.4em;
      height: 7.4em;
      margin-right: 2.7em;

      &.seguro {
        height: 6em;
      }
    }

    h3 {
      color: $brandLightRed;
      font-size: 1.5em;
      margin-bottom: .5em;
    }
  }
}