///////
//// Calculadora
/////////////////

#calculadora {

  .paids-information {
    display: flex;
    justify-content: space-around;
    min-height: 220px;
    margin-bottom: 80px;

    .input-content {
      margin-left: 0;
      padding-left: 0;

      p {
        margin-bottom: 15px;
      }

      .input-field {
        padding-left: 0;

        label {
          left: 0;
        }
      }
    }

    .mp-label {
      background: $siteBlue;

      .calculator-control-label {
        color: white;
      }
    }

    .cash-label {
      background: $brandRed;

      .calculator-control-label {
        color: white;
      }
    }

    .installment-label {
      background: $brandYellow;

      .calculator-control-label {
        color: $brandRed;
      }
    }


    .calculator-control-result {
      flex: 1;
      width: 200px;
      position: relative;
      border-radius: .5em;
      margin: 5px;
      height: 220px;

      .calculator-control-label {
        font-size: 1.2em;
        font-weight: 600;
        text-align: center;
        padding: 12px;
      }
    }

    .information-paids-box {
      background-color: $brandDarkBlue;
      border-radius: .5em;
      padding: 20px 20px 10px;
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;

      .advance-payment-container {
        align-items: baseline;
        color: $light;
        display: flex;
        justify-content: space-between;

        .advance-payment-text {
          color: $siteGreen;
          display: flex;
          font-size: 1.4em;
          margin: 0;
        }

        .advance-payment-amount {
          display: flex;
          font-size: 1.4em;
        }

        .mp-installment-text {
          font-size: 1.3em;
        }

        .mp-installment-cyberweek-text {
          font-size: 1em;
        }
      }

      .underlined {
        border-top: 1px solid $scaleGrey3;
        color: $light;
        display: flex;
        justify-content: space-between;
        font-size: 1em;
        margin-top: 5px;

        p {
          margin-top: 5px;
        }

        span {
          color: $siteGreen;
        }
      }

      .amount-paids {
        align-items: baseline;
        color: $light;
        display: flex;
        font-size: .9em;
        justify-content: space-between;

        .highlight-numbers {
          color: $siteGreen;
          display: flex;
          font-size: 2.4em;
        }

        .paid-number {
          align-items: baseline;
          display: flex;

          .highlight-numbers {
            margin: 0 5px;
          }
        }

        .discount-note-text {
          margin-left: 25px;
          margin-bottom: 10px;
        }
      }

      .total-paid {
        border-top: 1px solid $scaleGrey3;
        color: $light;
        display: flex;
        font-size: .8em;
        justify-content: space-between;
        padding-top: 5px;
        width: 100%;
      }

      .mercadopago-total-footer {
        flex-direction: column;
      }

      .main-btn {
        width: 100%;
        margin-top: 1em;
      }

      .calculator-footer {
        text-align: center;
        color: white;
        margin-top: auto;
        font-size: 12px;
        white-space: pre-line
      }
    }

    .submit-content {
      display: flex;
      align-items: center;
      justify-content: center;
    }

  }

  .warning-text {
    .warning {
      border-right: 1px solid $brandLightRed;
      color: $brandLightRed;
      font-size: 1.2em;
      font-weight: 700;
      margin: 0 20px 0 0;
      padding: 5px 10px;
    }

    p {
      font-size: 1em;
    }

    .terms-and-conditions-cyber-week {
      margin-top: 10px;

      span {
        color: $brandRed;
      }
    }
  }
}

.info-paids-box {

  display: flex;
  flex-direction: column;
  font-size: 1em;
  justify-content: center;
  margin-left: 2em;
  width: 20em;

  .primary-info {
    background-color: $brandRed;
    border-radius: .5em .5em 0 0;
    padding: 1.5em 1em;
    width: 100%;

    .title {
      color: $light;
      border-bottom: 1px solid $light;
      font-size: 1.3em;
      padding-bottom: 10px;
      text-align: center;
      text-transform: uppercase;
    }

    div.primary-info-content {
      ul.primary {
        margin: 1em 1em 0;

        &:first-child {
          margin: 1.5em 1em 0;

          padding-bottom: 1em;
          border-bottom: 1px solid;
          border-image: linear-gradient(to right, transparent, $light, transparent) 10% space;
        }

        li {
          color: $brandYellow;
          text-align: center;
          font-size: 1.4em;
          font-weight: 700;
          margin-bottom: 1em;

          &:last-child {
            margin-bottom: 0;
          }

          span {
            color: $light;
          }
        }
      }

      .observation {
        text-align: center;
        color: white;
        font-size: 0.8em;
      }
    }
  }

  div.info-for-installents {
    background-color: white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 15px;
    align-items: center;

    p {
      color: $brandRed;
      font-weight: 900;
      font-size: 1.2em;
      width: 80%;
      text-align: center;

      &:last-child {
        color: #ffb93b;
      }
    }

    .highlight-one {
      color: #ffb93b;
    }

    .highlight-two {
      color: $brandRed;
    }
  }

  .secondary-info {
    padding: 1.5em 1em;
    background-color: $light;
    border-radius: 0 0 .5em .5em;

    .main-btn {
      width: 100%;
      margin-top: 1em;
    }

    ul.secondary {
      margin: 0 1em;
      color: $brandRed;

      li {
        text-align: center;
        font-size: 1.4em;
        font-weight: 700;
        margin-bottom: 1em;

        .clarify-mp {
          font-size: 0.9em;
          margin: 0;
        }

        .with-mp-span {
          margin-bottom: 0;
          color: $dark;
        }

        .cyber-week-span {
          font-size: 0.9em;
          color: $siteBlue;
        }
      }
    }
  }

  .main-btn {
    margin-top: 1em;
  }
}

.calculator-helper-text {
  text-align: left;

  span {
    font-weight: 700;
  }

  ul {
    margin-top: 1em;

    li {
      list-style-type: disc;
      margin-left: 1em;
    }
  }

  .warning-text {
    display: block;
    font-size: .9em;

    span {
      color: $brandLightRed;
      font-size: 1.1em;
    }
  }
}

.calculate-row-container {
  display: flex;
  justify-content: center;
  align-items: center;

  .calculate-row-container-item {
    flex: 1;
  }

  .calculate-btn {
    justify-self: flex-end;
    margin-right: 10px;
    max-height: 40px;
  }
}

.calculator-option-text,
.calculator-values-text {
  font-size: 1.2em;
  margin-left: 0.75em;

  span {
    font-weight: 700;
  }
}

.calculator-option-text {
  margin-bottom: 0.75em;
}

.calculator-send-email-row {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  margin-left: 0.3em;

  .input-mail {
    color: black;
  }

  button {
    width: 120px;
    height: 40px;
    border-radius: 0.4rem;
    color: $light;
    margin-top: 15px;

    background-color: $siteBlue;
  }

  button:disabled,
  button[disabled] {
    border: 1px solid #999999;
    background-color: #cccccc;
    color: #666666;
  }


  .email-status {
    margin-left: 15px;
    vertical-align: center;
    font-weight: bold;
  }

  .error {
    color: red;
  }

  .success {
    color: green;
  }
}

.email-error-message {
  color: #9b0909;
  margin-left: 1em;
}

.fee-not-permitted-info {
  font-style: italic;
  text-align: center;
  font-weight: bold;
}