@media only screen and (max-width: 900px) {
  .landing-page-container .-landing-main-nav {
    height: 70px;
    .site-logo {
      width: 120px;
    }
  }
  .landing-site-cover {
    margin-top: 80px;
    .landing-background-container {
      max-height: 250px;
    }
    .text-container {
      font-size: 16px;
    }
  }
  .mercado-libre-warranty {
    height: 170px;
    .mercado-libre-container {
      .logo-mercado-libre {
        height: 40px;
      }
    }
    .red-card {
      width: 200px;
      justify-content: center;
      height: 180px;
      p {
        font-size: 2em;
      }
      .main-btn {
        font-size: 1.4em;
      }
    }
  }
}

@media only screen and (max-width: 860px) {
  .calculator-section .section-box {
    background-color: $scaleGrey2;
    border-radius: 0;
  }

  .main-footer.-landing-footer .company-info .app-logo {
    width: 120px;
  }

  .options-calculator-requirements{
    .complete-text{
      display: none;
    }
  }
}

@media only screen and (max-width: 760px) {

  nav.main-nav-bar.-landing-main-nav {
    height: 56px;
    padding: 0 1em;
    .site-logo {
      width: 100px;
    }
  }

  .landing-site-cover {
    margin-top: 66px;
    .landing-background-container {
      max-height: 200px;
    }
    .text-container {
      font-size: 14px;
      padding: 2em;
    }
  }

  .mercado-libre-warranty {
    font-size: 12px;
    padding: 0 2em;
    .mercado-libre-container {
      padding: 0 2em;
      p {
        margin: 0 1em 0 0;
      }
    }
    .red-card {
      width: 170px;
      height: 170px;
      .corner-vector {
        right: -22px;
        height: 17px;
      }
    }
  }

  .calculator-section .section-box#calculadora {
    background-color: $scaleGrey2;
    padding: 3.3em 4em;
    margin-bottom: 1em;
  }

  .main-footer.-landing-footer .company-info {
    .app-logo {
      margin-left: 0;
    }
    .info {
      border-right: 0;
      text-align: center;
      margin-right: 0;
      padding-right: 0;
      font-size: 1.2em;
    }
  }

  .bar-follow-warranty{
    p, a {
      font-size: 1em;
    }

    .long-redirect-text{
      display: none;
    }

    .short-redirect-text{
      display: block;
    }
  }
}

@media only screen and (max-width: 600px) {

  .mercado-libre-warranty {
    padding: 1em;
    height: auto;
    .mercado-libre-container {
      display: flex;
      flex-direction: column;
      margin-right: 2em;
      align-items: center;
      padding: 0;
      p {
        text-align: center;
        margin-right: 0;
        margin-bottom: .5em;
      }
    }
    .red-card {
      transform: none;
      height: 130px;
      border-radius: 1em;
    }
    .corner-vector {
      display: none;
    }
  }
  .calculator-section .section-box#calculadora {
    background-color: $scaleGrey2;
    padding: 3em 2em;
  }
}

@media only screen and (max-width: 500px) {

  .landing-site-cover {
    .text-container {
      font-size: 10px;

      .clarify-text {
        .span-sub-text {
          font-size: 0.9rem;
          line-height: 0.8em;
        }
      }
    }
  }

  .mercado-libre-warranty .mercado-libre-container {
    margin-right: 1em;
    .logo-mercado-libre {
      height: 35px;
    }
  }

}

@media only screen and (max-width: 400px) {

  .landing-site-cover {
    .text-container {
      font-size: 8px;

      .clarify-text {
        font-size: 0.9rem;
        line-height: 0.8em;
      }
    }
  }

  .mercado-libre-warranty {

    .red-card .main-btn {
      padding: .1em .5em;
    }

    .mercado-libre-container {
      .basic-text {
        font-size: 1.4em;
      }
      .logo-mercado-libre {
        height: 30px;
      }
    }
    .red-card {
      font-size: 11px;
      width: auto;
      height: auto;
      padding-bottom: .8em;
      p {
        font-size: 1.6em;
      }
      .main-btn {
        font-size: 1.3em;
        min-width: 120px;
        margin: .5em;
      }
    }
  }

  .main-footer.-landing-footer .company-info .info {
    font-size: 1em;
  }

}