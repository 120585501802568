// DropZone

.file-upload {
  .dropzone {
    align-items: center;
    background-color: $scaleGrey2;
    color: $light;
    border: 2px dashed $scaleGrey3;
    display: flex;
    border-radius: .4em;
    height: 100px;
    justify-content: center;
    margin: 1em 0;
    padding: 1em;
    text-align: center;
    width: 100%;
    &:focus {
      border: 2px dashed $siteBlue;
      outline: none;
    }
    svg {
      font-size: 3em;
      margin: 0;
      opacity: .2;
    }
  }
  aside {
    ul {
      margin-top: .5em;
      max-height: 100px;
      overflow-x: hidden;
      overflow-y: auto;
      li {
        align-items: center;
        background-color: #DDDDDD;
        border-radius: 10px;
        display: flex;
        margin-top: 1em;
        padding: .5em;
        position: relative;
        &:first-child {
          margin-top: 0;
        }
        .state-upload {
          margin-right: 1em;
          &.-cargando {
            color: $brandLightRed;
          }
          &.-enviado {
            color: $siteBlue;
          }
        }
        .button-erase {
          align-items: center;
          background-color: white;
          border-radius: 30px;
          display: flex;
          height: 25px;
          justify-content: center;
          position: absolute;
          right: .5em;
          top: .5em;
          width: 25px;
        }
      }
    }
    .main-btn {
      padding: .3em 1.7em;
      width: 100%;
    }
    .warning-text {
      font-style: inherit;
      margin-top: 1em;
    }
  }

}