// Animations

@keyframes appear {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes grow {
  from {
    transform: scale(1, 1);
  }
  to {
    transform: scale(1.5, 1.5);
  }
}

//////////////// Request help

@keyframes text-grow {
  0% {transform: scale(1);}
  25% {transform: scale(1.1);
    color: $brandLightRed;
    transform-origin: left;}
  75% {transform: scale(1.1);
    color: $brandLightRed;
    transform-origin: left;}
  100%{transform: scale(1);}
}

@keyframes text-beat {
  0% {transform: scale(1);}
  50% {transform: scale(1.07);}
  100%{transform: scale(1);}
}


////////////////////////////////////////// Home background

svg#ramas {
  position: absolute;
  fill: #FCCC7E;
}

path#ramas1 {
  animation: show1 3s infinite;
}
@keyframes show1 {
  0% {opacity: 1;}
  5.5% {opacity: 1;}
  11%{opacity: 0;}
  100%{opacity: 0;}
}

path#ramas2 {
  animation: show2 3s infinite;
}
@keyframes show2 {
  0% {opacity: 0;}
  5.5% {opacity: 0;}
  11%{opacity: 1;}
  16.5%{opacity: 0;}
  100%{opacity: 0;}
}

path#ramas3 {
  animation: show3 3s infinite;
}
@keyframes show3 {
  0% {opacity: 0;}
  11%{opacity: 0;}
  16.5%{opacity: 1;}
  22%{opacity: 0;}
  100%{opacity: 0;}
}

path#ramas4 {
  animation: show4 3s infinite;
}
@keyframes show4 {
  0% {opacity: 0;}
  16.5%{opacity: 0;}
  22%{opacity: 1;}
  27.5%{opacity: 0;}
  100%{opacity: 0;}
}

path#ramas5 {
  animation: show5 3s infinite;
}
@keyframes show5 {
  0% {opacity: 0;}
  22%{opacity: 0;}
  27.5%{opacity: 1;}
  33%{opacity: 0;}
  100%{opacity: 0;}
}

path#ramas6 {
  animation: show6 3s infinite;
}
@keyframes show6 {
  0% {opacity: 0;}
  27.5%{opacity: 0;}
  33%{opacity: 1;}
  38.5%{opacity: 0;}
  100%{opacity: 0;}
}

path#ramas7 {
  animation: show7 3s infinite;
}
@keyframes show7 {
  0% {opacity: 0;}
  33%{opacity: 0;}
  38.5%{opacity: 1;}
  44%{opacity: 0;}
  100%{opacity: 0;}
}

path#ramas8 {
  animation: show8 3s infinite;
}
@keyframes show8 {
  0% {opacity: 0;}
  38.5%{opacity: 0;}
  44%{opacity: 1;}
  49.5%{opacity: 0;}
  100%{opacity: 0;}
}

path#ramas9 {
  animation: show9 3s infinite;
}
@keyframes show9 {
  0% {opacity: 0;}
  44%{opacity: 0;}
  49.5%{opacity: 1;}
  55%{opacity: 0;}
  100%{opacity: 0;}
}

path#ramas10 {
  animation: show10 3s infinite;
}
@keyframes show10 {
  0% {opacity: 0;}
  49.5%{opacity: 0;}
  55%{opacity: 1;}
  60.5%{opacity: 0;}
  100%{opacity: 0;}
}

path#ramas11 {
  animation: show11 3s infinite;
}
@keyframes show11 {
  0% {opacity: 0;}
  55%{opacity: 0;}
  60.5%{opacity: 1;}
  66%{opacity: 0;}
  100%{opacity: 0;}
}

path#ramas12 {
  animation: show12 3s infinite;
}
@keyframes show12 {
  0% {opacity: 0;}
  60.5%{opacity: 0;}
  66%{opacity: 1;}
  71.5%{opacity: 0;}
  100%{opacity: 0;}
}

path#ramas13 {
  animation: show13 3s infinite;
}
@keyframes show13 {
  0% {opacity: 0;}
  66%{opacity: 0;}
  71.5%{opacity: 1;}
  77%{opacity: 0;}
  100%{opacity: 0;}
}

path#ramas14 {
  animation: show14 3s infinite;
}
@keyframes show14 {
  0% {opacity: 0;}
  71.5%{opacity: 0;}
  77%{opacity: 1;}
  82.5%{opacity: 0;}
  100%{opacity: 0;}
}

path#ramas15 {
  animation: show15 3s infinite;
}
@keyframes show15 {
  0% {opacity: 0;}
  77%{opacity: 0;}
  82.5%{opacity: 1;}
  88%{opacity: 0;}
  100%{opacity: 0;}
}

path#ramas16 {
  animation: show16 3s infinite;
}
@keyframes show16 {
  0% {opacity: 0;}
  82.5%{opacity: 0;}
  88%{opacity: 1;}
  93.5%{opacity: 0;}
  100%{opacity: 0;}
}

path#ramas17 {
  animation: show17 3s infinite;
}
@keyframes show17 {
  0% {opacity: 0;}
  88%{opacity: 0;}
  93.5%{opacity: 1;}
  100%{opacity: 0;}
}

path#ramas18 {
  animation: show18 3s infinite;
}
@keyframes show18 {
  0% {opacity: 0;}
  93.5%{opacity: 0;}
  100%{opacity: 1;}
}


// CORONAVIRUS MODAL

@keyframes slide-right {
  0% {opacity: 0;
    transform: translateX(-60px);}
  100%{opacity: 1;
    transform: translateX(0);}
}

@keyframes appear {
  0% {opacity: 0;}
  100%{opacity: 1;}
}