body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}


.small-label {
  font-size: 12px!important;
  padding-left: 21px!important;
  line-height: 21px!important;
}

[type="checkbox"].custom-checkbox {
  width: 10px!important;
  height: 10px!important;
}

[type="checkbox"].custom-checkbox + label {
  padding-left: 22px!important;
  font-size: 12px!important;
}