// Colors

$brandRed:         #E30613;
$brandLightRed:    #E30613;
$brandYellow:      #FED287;
$brandGrey:        #A0B1B5;
$brandDarkBlue:    #2F3743;

$siteBlue:         #4D8DC0;
$siteGreen:        #3AE6DF;

$light:            #FFFFFF;
$scaleGrey1:       #F2F2F2;
$scaleGrey2:       #DDDDDD;
$scaleGrey3:       #B7B7B7;
$dark:             #2F3743;

// Elements colors

$btnColor:         $siteBlue;
$btnHoverColor:    darken($btnColor, 5%);
$btnColorAlt:      $siteGreen;
$btnHoverColorAlt: darken($btnColorAlt, 5%);
