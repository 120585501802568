///////
//// Inmobiliarias
/////////////////

.real-estate {

  .card {
    overflow: hidden;

    .card-image {
      align-items: center;
      display: flex;
      justify-content: center;
      height: 158px;
    }

    .card-content, .card-reveal {

      .card-title {
        display: flex;
        justify-content: space-between;
        line-height: 26px;
        text-transform: inherit;
      }
    }
  }
}

.pagination {
  display: flex;
  justify-content: center;
  margin: 0;
  padding: 3em 0;
}

.card .card-image img {
  max-width: 100%;
  max-height: 100%;
  width: auto;
}