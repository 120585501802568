// Forms Mobile

@media only screen and (max-width : 1200px) {
  .-info-confirmation{
    &::after{
      transform: translate(-260px, 180px);
    }
  }

  .-participant-sex-container{
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;

    .sex-select{
      width: 100%;
    }
  }


  .-mail-btn-container{
    width: 100%;
  }
}

@media only screen and (max-width : 860px) {

  .row {
    margin-bottom: 0;
  }

  .combined-input-label {
    margin-top: 1.5em;
  }

  .content-radio-buttons {
    margin: 2em 0;
  }

  .-info-confirmation{
    &::after{
      transform: translate(-260px, 200px);
    }
  }

  .-info-email-confirmation-sent{
    margin-bottom: 10px;
  }

  .-info-email-verified{
    &::after{
      transform: translate(-260px, 200px);
    }
  }

  .mail-confirmation-warning-container {
    margin: 1em auto;
    width: 90%;
  }

  .rental-type-and-duration-row{
    width: 100%;
    flex-direction: column;
    justify-content: space-around;
    margin: 0;
    padding: 0;

    .content-radio-buttons {
      margin: 15px 0 0 0;
      width: 100%;
    }

    .content-radio-buttons-rental-type {
      margin: 0;
      width: 100%;
    }
  }


}