///////
//// Solicitud de Garantía
/////////////////

.layout-request {
  //background-color: $scaleGrey1;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;

  .mail-container {
    width: 610px;
    font-family: 'Montserrat', sans-serif;
    background-color: #ffffff;
  }

  main {
    flex-grow: 1;
  }
}

///////
//// Header Solicitud
/////////////////

header.request-nav-bar {
  height: 5em;
  background-color: $light;
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.10);
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 2em;

  .site-logo {
    width: 8em;
    height: auto;
    line-height: 0;
    transition: width .3s;
  }

  .request-user {
    display: flex;
    align-items: center;

    .user-name {
      color: $brandDarkBlue;
      font-size: 1.2em;
      font-weight: 700;
      border-right: 1px solid $scaleGrey3;
      padding-right: 1em;
      margin-right: 1em;
    }
  }
}

///////
//// Footer Solicitud
/////////////////

footer.request-footer {
  background-color: $light;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 2em;
  height: 3.5em;

  .contact-info {
    display: flex;
    align-items: center;

    .info-item {
      display: flex;
      align-items: center;
      color: $brandGrey;
      margin-right: 1em;

      .icon {
        margin-right: .5em;
      }
    }
  }

  .social-info a {
    color: $brandGrey;
    font-size: 1.2em;
    margin-left: 1em;

    &:hover {
      color: $siteBlue;
    }
  }
}

///////
//// Ingreso Solicitud
/////////////////

.layout-request.begin {

  header {
    text-align: center;
    padding-top: 2em;

    .app-logo {
      width: 10em;
    }
  }

  main {
    text-align: center;
    padding: 3em 0 2em;
  }

  h1 {
    font-size: 2.2em;
    font-weight: 700;
    color: $brandDarkBlue;
    margin-bottom: .3em;
  }

  .middle-bar {
    background-color: $light;
    box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.10);
    display: flex;
    justify-content: center;
    margin: 2em 0;
    text-align: left;

    // Animated!
    .middle-bar-content {
      opacity: 0;
      width: 40em;
      animation-name: appear;
      animation-duration: 1s;
      animation-fill-mode: forwards;
    }
  }

  .direction-btn {
    margin-right: 1em;
  }
}

///////
//// Dashboard Solicitud
/////////////////

.dashboard main {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 3em 0;
}

.dashboard-col {
  width: 21em;
  margin: 0 3em;

  h1 {
    font-size: 3em;
    font-weight: 700;
    color: $brandDarkBlue;
    margin-bottom: 1em;
  }

  &.-right {
    text-align: center;
  }
}

.request-code {

  .code {
    font-size: 3.3em;
    text-transform: uppercase;
    color: $siteBlue;
    margin: 0;
  }
}

.request-state {
  display: flex;
  align-items: center;
}

.dashboard-nav {
  margin-top: 2.5em;
}

.dashboard-progressbar {
  width: 16em;

  .CircularProgressbar-trail {
    stroke: $scaleGrey2;
  }

  .CircularProgressbar-path {
    stroke: $siteBlue;
  }

  .CircularProgressbar-text {
    fill: $siteBlue;
  }
}

.completed-forms-message {
  display: flex;
  justify-content: center;
  position: relative;

  .title {
    bottom: 0;
    color: $siteBlue;
    font-size: 1.6em;
    margin-bottom: 5.7em;
    position: absolute;
  }

  .message {
    color: $siteBlue;
    font-weight: 200;
    font-size: 1.2em;
    margin-top: .5em;
  }
}

.assigned-office {
  margin-top: 3.5em;

  .label {
    color: $siteBlue;
    font-size: 1.3em;
    font-weight: 700;
    margin-bottom: .5em;
  }

  .office {
    text-transform: uppercase;
  }

  a {
    color: $dark;
    width: 100%;
  }

  span {
    color: $siteBlue;
  }
}

///////
//// Historial Solicitudes
/////////////////

.record main {
  padding: 3em 2em;
  display: flex;
  justify-content: center;

  .request-content {
    width: 820px;

    .back-btn {
      font-size: 1.2em;
      margin-bottom: 1.5em;
    }

    h1 {
      font-size: 2.3em;
      color: $brandDarkBlue;
      font-weight: 700;
      border-bottom: 1px solid $scaleGrey3;
      margin-bottom: .5em;
      padding-bottom: .5em;
    }
  }
}

.record-item {
  display: flex;
  position: relative;
  background-color: $light;
  border-radius: .4em;
  margin-bottom: 1em;
  padding: 1.5em 2.5em;
  color: $dark;
  box-shadow: 0 0 0 0 $siteBlue;
  transition: box-shadow .3s;

  .code {
    padding-right: 3em;

    .data {
      font-weight: 700;
    }
  }

  .state {
    padding-right: 3em;
  }

  .address {
    flex-grow: 1;
  }

  .label {
    font-size: .9em;
    color: $scaleGrey3;
  }

  .data {
    font-size: 1.1em;
  }

  .hover-data {
    color: $siteBlue;
    position: absolute;
    top: 1em;
    right: 1em;
    opacity: 0;
    transition: opacity .3s;
  }

  &:hover {
    box-shadow: 0 0 0 1px $siteBlue;

    .hover-data {
      opacity: 1;
    }
  }
}

///////
//// Feedback presolicitud
/////////////////

.layout-request.begin .pre-request-title {
  @extend h1;
  color: $siteBlue;
}

.new-text-id {
  font-size: 1.7em;
  font-style: italic;
  color: $brandLightRed;
  text-align: center;
  text-shadow: none;
  font-weight: 700;
}

.pre-request-result {
  align-items: center;
  display: flex;
  flex-direction: column;

  .secondary-title {
    margin-bottom: 1.5em;
    position: relative;
    bottom: 0;

    .helper-modal-btn {
      position: absolute;
      font-size: .6em;
      margin-left: .7em;
      bottom: .4em;
      top: inherit;
      right: 4em;
    }
  }

  .basic-text {
    max-width: 520px;
    margin-bottom: 2em;
  }

  .exclamation-text {
    font-weight: 700;
    font-size: 1.4em;
    margin-bottom: 1.5em;
    position: relative;

    .helper-modal-btn {
      position: absolute;
      font-size: .6em;
      margin-left: .7em;
      bottom: .4em;
      top: -2em;
      right: -2em
    }

  }

  .link {
    color: $siteBlue;
    text-decoration: underline;

    &:hover {
      text-decoration: none;
    }
  }

  .contact {
    align-items: center;
    display: flex;
    flex-direction: column;
    position: absolute;
    bottom: 0;
    max-width: 550px;
    padding: 0 2em;
    margin-bottom: 3em;

    .contact-items-group {
      display: flex;
      margin-top: 2em;
      width: 100%;
      justify-content: space-around;
    }
  }

  .link-btn-pre-request {
    color: $siteBlue;
    text-decoration: underline;
    cursor: pointer;

    &:hover {
      text-decoration: none;
    }
  }
}

.renewal-request {
  @extend .pre-request-result;

  .renewal-contact {
    align-items: center;
    display: flex;
    flex-direction: column;
    position: absolute;
    bottom: 0;
    max-width: 550px;
    padding: 0 2em;
    margin-bottom: 3em;

    .renewal-contact-items-group {
      display: flex;
      margin-top: 2em;
      width: 100%;
      align-items: center;
      flex-direction: column;

      .renewal-contact-item {
        display: flex;
        margin-bottom: 1em;

        p {
          text-align: inherit;
        }
      }
    }
  }

  i {
    margin-right: 15px;
  }

  p {
    text-align: center;
  }
}

///////
//// Seleccionar método de pago
/////////////////

.select-payment-method-btn {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: start;
  font-size: 1.4em;
  padding: .5em 1.2em;

  p {
    margin-left: 15px;
    font-weight: bold;
  }
}


.payment-bar {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 45vh;

  p {

    span {
      font-weight: bold;
      font-size: 1.3em;
    }

    .span-garantia {
      color: $brandRed;
    }

    .span-precio {
      color: $siteBlue;
    }
  }

  .remark-discount {
    color: $siteBlue;
    font-size: 1.2em;

    span {
      font-weight: bold;
    }
  }

  .payment-information-text {
    margin: 10px 0px;
    text-align: center;

    span {
      font-size: inherit;
      font-weight: bold;
    }
  }

  .aclaration {
    font-size: 0.9rem;
  }

  .remark-black-week-discount {
    color: $siteBlue;
    font-size: 1.4em;
  }

  .one-promotion-text {
    margin: 10px 0px;
  }
}

.cash-payment {
  font-size: 1.2em;

  .payment-information-text {
    margin: 8px 0px;
  }

  .branch-office {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 15px;
    border: solid 1.5px;
    border-radius: 10px;
    font-size: 1.2em;
    margin: 10px 0px;

    .site-logo {
      margin-right: 15px;

      .app-logo {
        width: 6em;
      }
    }

    .client-address {
      font-size: 0.8em;
      display: flex;
      flex-direction: column;
      align-self: flex-end;

      h2 {
        font-size: 1em;
        text-transform: uppercase;
        font-weight: bold;
        margin-bottom: 6px;
        color: $dark;
      }

      .address-street {
        display: flex;
        flex-direction: row;
        align-self: center;
        justify-content: space-around;

        svg {
          margin-right: 5px;
          align-self: center;
        }
      }
    }
  }
}

.transfer-payment {
  font-size: 1.2em;
}

.transfer-payment-end {
  font-size: 1.2em;

  .payment-information-text {
    margin: 10px 0px;
    text-align: center;

    .span-warning {
      color: $brandLightRed;
    }
  }
}

.discount-text {
  color: $siteBlue;
  font-size: 18px;
  font-weight: bold;
}

.select-payment-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  font-size: 1.4em;
  width: 450px;

  p {
    margin-left: 15px;
  }
}

.payment-btn-wrap {
  margin-bottom: 10px;
  margin-top: 10px;
}

.arrows-payment {
  .confirm-payment-btn {
    font-weight: bold;
    font-size: 1.1em;
    border-radius: 1.2em;
  }
}

.mercadopago-wallet-options {
  display: flex;
  justify-content: center;
  align-items: center;

  .mercadopago-button {
    background: none;
    line-height: normal;
  }
}

.installments-bar {

  .payment-information-text {
    span {
      font-size: 1.2em;
    }
  }

  .select-installments {
    width: 80%;
  }

  .interest-rates-message {
    margin-top: 30px;
    text-align: center;
  }
}

///////
//// Mercadopago pago
/////////////////

.mercadopago-container {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  height: 100%;

  .slider-nav {
    display: flex;
    flex-direction: row;
    width: 10%;
    justify-content: space-around;

    svg {
      width: 0.5em;
    }

    .circle {
      color: $scaleGrey2;
    }

    .-active {
      color: $siteBlue;
    }
  }
}

.mercado-pago-payment {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 85%;
  gap: 5px;
  // width: 45em;

  .mp-card-form {
    height: 70%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 50%;

    .credit-row {
      display: flex;
      // flex-direction: column;
      justify-content: space-between;
      align-items: center;
      width: 100%;

      .input-field {
        margin: 10px 5px;
      }

      input {
        padding: 0px;
        margin: 0px;
        height: inherit;
      }
    }

    .nid-number,
    .select-nid-type {
      width: 100%;
    }

    .cardholder-name {
      flex: 2;
    }

    .security-code {
      flex: 1;
    }

    .card-number {
      flex: 2;
    }

    .expiration-date {
      justify-content: flex-end;
      align-items: center;
      flex: 1;

      .expiration-date-box {
        width: 25px;
      }
    }
  }

  .nid-form {
    width: 45%;
    justify-content: center;
    margin-left: 10px;
  }

  .nid-card {
    background: #D7E4FC;
    width: 300px;
    height: 190px;
    border-radius: 15px;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;

    .nid-card-image {
      height: 80px;
      width: 50%;
      display: flex;
      justify-content: center;
      align-items: center;

      .icon-user-nid {
        display: flex;
        justify-content: center;
        align-items: center;
        background: white;
        height: inherit;
        width: 80px;

        svg {
          height: 50px;
          width: 50px;
        }
      }
    }

    .nid-card-number {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      width: 50%;

      .nid-type-field {
        font-size: 1.2rem;
      }

      .nid-number-field {
        font-size: 1.4rem;
      }

    }
  }

  .installments-container {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    width: 100%;
    height: 100%;

    .select-installments-container {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 10px;
      height: 60%;
      width: 100%;

      h2 {
        font-size: 1.2rem;
        font-weight: bold;
      }

      p {
        font-size: 1rem;
        text-align: center;
        margin-top: auto;
      }

      .react-select {
        width: 60%;
      }
    }
  }

  .card-data-container {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    width: 100%;
    height: 100%;

    h2 {
      font-size: 1.2rem;
      margin-bottom: 10px;
    }
  }

  .card-warning-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    p {
      text-align: center;
      font-size: 18px;
    }
  }
}