// Forms

.row {
  margin-right: -.75rem;
  margin-left: -.75rem;
}

.input-field {
  margin-top: 2.5rem;
}

.input-field.col label {
  width: 95%;
}

.combined-input.col {
  padding: 0;

  &.-helper {
    position: relative;

    .helper-modal-btn {
      bottom: 2em;
      top: inherit;
    }
  }

  .input-field {
    margin-top: 1rem;
    position: relative;
  }

  .-helper-space {
    width: 90%;
  }
}

.select-wrapper {
  .dropdown-content {
    max-height: 200px;
  }
}

input:not([type]):focus:not([readonly]) {
  border-bottom: 1px solid $siteBlue;
  -webkit-box-shadow: 0 1px 0 0 $siteBlue;
  box-shadow: 0 1px 0 0 $siteBlue;
}

/////////////// REACT-SELECT ///////////////

.react-select {
  input[type=text]:not(.browser-default) {
    margin: 0;
  }

  Select {
    display: none;
  }

  .css-2b097c-container {
    padding: 0 .75rem;
    position: relative;
    transform: translateY(-.6em);
    z-index: 1;

    .css-yk16xz-control {
      background-color: transparent;
      border-color: transparent;
      border-bottom: 1px solid $scaleGrey3;
      border-radius: inherit;
      box-sizing: content-box;
    }
  }

  .css-1uccc91-singleValue {
    color: $dark;
  }

  .css-1pahdxg-control {
    background-color: transparent;
    border-color: transparent;
    border-bottom: 1px solid $scaleGrey3;
    box-shadow: none;
    border-radius: inherit;

    &:hover {
      border-color: transparent;
      border-bottom: 1px solid $siteBlue;
    }
  }

  .css-1wy0on6 {
    border-bottom: 1px solid $scaleGrey3;
    display: none;
  }

  .css-1g48xl4-IndicatorsContainer {
    border-bottom: 1px solid $scaleGrey3;
    display: none;
  }

  .css-w8afj7-Input {
    margin: 1px;
    padding-bottom: 1px;
    padding-top: 1px;
  }

  .css-1hwfws3 {
    padding: 2px 0;
  }

  .css-26l3qy-menu {
    filter: grayscale(100%);
  }
}

.-padding-adjustment {
  margin-right: -.75rem;
  margin-left: -.75rem;
}

.-add-padding {
  padding: 0 1em;
}

.-helper-space {
  padding-right: 2em;
}

.-disable-select>div {
  & div>div {
    margin-left: 12px;
    top: 40%;
  }

  border-width: 0;
}

/// this class fixes the react-select overlap ///
.-overlap-fix-above {
  position: relative;
  z-index: 1;
}

.-overlap-fix-below {
  position: relative;
  z-index: 0;
}

/////////////// AUTOCOMPLETE ///////////////

.-autocomplete {
  .combined-input-label {
    float: initial;
  }

  .new-autocomplete {
    padding-top: 1rem;
    position: relative;
    box-sizing: content-box;

    input::placeholder {
      color: $dark;
      opacity: .8;
    }

    .autocomplete-list {
      background-color: $light;
      border: 1px solid lightgrey;
      border-radius: .3em;
      box-shadow: 10px 10px 12px -13px rgba(0, 0, 0, 0.53);
      margin-top: -12px;
      max-height: 185px;
      overflow: hidden;
      overflow-y: auto;
      padding: .3em 0;
      position: absolute;
      width: 100%;

      .autocomplete-item {
        background-color: $light;
        padding: .5em;

        &:hover {
          background-color: $scaleGrey1;
        }
      }
    }
  }
}

.input-field .new-autocomplete {
  padding: 0;
}

.input-field.-helper {
  .new-autocomplete {
    padding-top: 1rem;
    padding-right: .75rem;
    padding-left: .75rem;
  }
}

.-helper {
  input {
    width: 90%;
  }
}

///////////////

.combined-input-label {
  color: $brandDarkBlue;
  width: 100%;
  float: left;
  padding-left: .75rem;

  &.-half {
    width: 50%;
  }
}

.submit-row {
  margin: 0;

  .message {
    color: $scaleGrey3;
    float: left;
    width: 50%;
  }

  .main-btn {
    float: right;
  }
}

/* label focus color */
.input-field input:focus+label {
  color: $siteBlue !important;
}

/* label underline focus color */
.row .input-field input:focus {
  border-bottom: 1px solid $siteBlue !important;
  box-shadow: 0 1px 0 0 $siteBlue !important
}

textarea.materialize-textarea:focus:not([readonly])+label {
  color: $siteBlue !important;
}

textarea.materialize-textarea:focus:not([readonly]) {
  border-bottom: 1px solid $siteBlue !important;
  box-shadow: 0 1px 0 0 $siteBlue !important
}

.content-radio-buttons {
  margin: 0 0 30px 0;
  // border-bottom: 1px solid $scaleGrey3;
  padding-bottom: 1.5em;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;

  .col.-text {
    flex-grow: 1;
  }

  .radio-button-option {
    align-items: center;
    display: flex;

    p {
      margin: 0 0 0 .5em;
    }
  }
}

.content-radio-buttons-rental-type {
  @extend .content-radio-buttons;
  margin: 20px 0 0 0;
}

.rental-type-and-duration-row {
  width: 100%;
  padding: 0.75rem 0;
  display: flex;
  align-items: flex-start;

  .content-radio-buttons {
    margin: 0 0 0 10px;
    flex: 1;
  }

  .content-radio-buttons-rental-type {
    margin: 0 10px 0 0;
    display: flex;
    flex-direction: column;
    gap: 10px;
    align-items: flex-start;
  }
}

.content-contract-period {
  width: 33%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.col.-text {
  padding: 0;
}

[type="radio"]:not(:checked)+label,
[type="radio"]:checked+label {
  line-height: 1.2em;
}

[type="radio"]:checked+label:after,
[type="radio"].with-gap:checked+label:after {
  background-color: $siteBlue;
}

[type="radio"]:checked+label:after,
[type="radio"].with-gap:checked+label:before,
[type="radio"].with-gap:checked+label:after {
  border-color: $siteBlue;
}

.form-subtitle {
  color: $brandLightRed;
  font-weight: 700;
  font-size: 1.3em;
  margin-top: 1.5em;
}

.file-field {
  margin-top: 1rem;

  .btn {
    background-color: $siteBlue;
    box-shadow: none;
    border-radius: .4em;
    padding: 0 1.5rem;

    &:hover {
      background-color: $btnHoverColor;
    }
  }
}

[type="radio"]:not(:checked),
[type="radio"]:checked {
  position: relative;
  opacity: 1;
  pointer-events: auto;
}

.autocomplete-content {
  max-height: 150px;
  position: absolute;
}

//// Menu mobile for fomrs

.form-nav.-mobile-menu {
  font-size: .9em;
  height: 100vh;
  padding-top: 4.5em;
  position: fixed;
  right: -22em;
  transition: right ease .5s;
  z-index: 5;

  .overlay-form-mobile {
    position: fixed;
    z-index: 1000;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.3);
    opacity: 1;
    transition: opacity 0.3s ease 0s;
  }
}

.form-mobile-open {
  display: none;
}

button.form-mobile-close,
.logout-btn {
  display: none;
}

.request-user {
  .logout-btn {
    display: flex;
  }
}

.overlay-form-menu {
  background: rgba(0, 0, 0, 0.3);
  height: 100%;
  opacity: 0;
  position: fixed;
  top: 0;
  transition: opacity 0.3s ease 0s;
  width: 100%;
  z-index: 4;
}

.-open-form-menu.form-nav.-mobile-menu {
  right: 0;
}

.-open-form-menu.overlay-form-menu {
  opacity: 1;
}


/// User help for request form

.-user-help-active {
  color: $siteBlue;

  p {
    animation: text-beat 2.5s infinite;
    animation-timing-function: ease-in-out;
    font-size: 1.1em;
    margin-left: .15em;
  }
}



.-user-help-disable {
  color: $scaleGrey2;

  &:hover {
    color: $dark;
  }
}

.-participant-sex-container {
  padding: 0 10px;
  display: flex;
  flex-direction: row;
  align-items: center;

  .sex-select {
    width: 50%;
  }
}

.-mail-btn-container {
  position: relative;
  display: flex;
  flex-direction: row;
  width: 50%;

  .mail-btn {
    top: 45px;
    right: 16px;
    background-color: $siteBlue;
    color: $light;
    position: absolute;
    padding: 5px 8px;
    border-radius: 5px;
    transition: background-color, box-shadow .3s;

    &:hover {
      background-color: $btnHoverColor;
    }

    &:focus {
      background-color: $btnHoverColor;
    }

    .-disabled {
      background-color: $scaleGrey3;
    }
  }

}

.-info-confirmation {
  &::after {
    content: "Debes confirmar este email";
    color: $brandRed;
    position: absolute;
    font-size: 0.9em;
    right: 0;
    font-weight: bold;
    transform: translate(-204px, 89px);
  }
}

.-info-email-confirmation-sent {
  &::after {
    content: "Se ha enviado un email de verificacion a la casilla de correo";
    color: $siteBlue;
    position: absolute;
    font-size: 0.9em;
    right: 0;
    font-weight: bold;
    transform: translate(-19px, 89px);
  }
}

.-info-email-verified {
  &::after {
    content: "Email verficado";
    color: $siteBlue;
    position: absolute;
    font-size: 0.9em;
    right: 0;
    font-weight: bold;
    transform: translate(-272px, 89px);
  }
}

.birthdate-field {
  width: 50%;
  flex: 1;
  margin-right: 15px;
}

.sex-select-field {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-left: 5px;
}

.birthday-and-sex-row {
  margin-left: 0;
  display: flex;
}

/// Spinners for forms

.layout-request.form main {
  position: relative;

  .spinner-save-content {
    align-items: center;
    display: flex;
    justify-content: center;

    p {
      font-size: 1.1em;
    }

    .spinner-save {
      animation: none;
      margin-right: -1em;
      transform: scale(0.5);
      transition: none;
    }
  }

  .spinner-saved {
    display: flex;

    p {
      font-size: 1.1em;
      margin-right: 1em;
    }

    svg {
      color: $siteBlue;
      height: 20px;
      width: 20px;
    }
  }

  .spinner-checked {
    @extend .spinner-saved;
    margin-left: 20px;
    color: $siteBlue;

    .helper-modal-btn {
      position: inherit;
      margin-left: 15px;
    }
  }

  .checked-icons {
    transform: scale(0.8, 0.8);
    display: flex;
    align-items: center;
  }
}

.mail-confirmation-warning-container {
  display: flex;
  flex-direction: row;
  background-color: lighten($brandLightRed, 8%);
  padding: 7px;
  margin: 1em 6em;
  align-items: center;
  border-radius: 5px;
  justify-content: center;
  max-width: 730px;
  width: 100%;
  position: fixed;
  bottom: 0;
  z-index: 3;

  p {
    text-align: center;
    color: $light;
  }

  button {
    align-self: flex-start;
  }
}

.terms-and-conditions-container {
  margin-top: 2em;

  p {
    margin-left: 2.2em;
  }
}