// Sucursales Mobile

@media only screen and (max-width: 1100px) {

  section.main-office {
    font-size: .9em;
  }
  section.headquarters {
    font-size: .9em;

    .card-group .card .card-content, .card-group .card .card-reveal {
      padding: 24px 20px 30px 25px;
    }
  }

  .headquarters .headquarters-group .card-group .card .card-image img {
    width: 60%;
  }
}

@media only screen and (max-width: 860px) {

  .main-office .box-container .contact-info .contact-item {
    display: flex;
    margin: 0 10px 20px;
  }

  .card-group .card {
    margin: 20px 1.5%;
    width: 47%;
  }
}

@media only screen and (max-width: 760px) {

  .card-group .card .card-content .card-title {
    font-size: 1.3em;
  }

  .title {
    font-size: 2em;
  }

  .subtitle {
    font-size: 1.3em;
  }

  .main-office {

    .finaer-logo {
      display: block;
      margin: 1.3em auto 3em;
      width: 10.7em;
    }

    .map {
      display: none;
    }

    .contact-info {
      flex-direction: column;
      text-align: left;
      padding: 0;

      .contact-item {
        margin: 0 0 1em 0;
      }
    }
  }

  .headquarters {

    .headquarters-group {

      .group-title {
        margin-bottom: 1em;
      }

      .card-group .card {
        height: 115px;
        margin: 7px 0;
        width: 100%;

        .card-image {
          height: 0;
          padding-top: 0;

          img {
            display: none;
          }
        }

        .card-content, .card-reveal {
          padding: 12px 20px 15px 25px;
        }

        .card-reveal .contact-item {
          display: flex;

          &:nth-child(2) {
            display: none;
          }
        }
      }
    }
  }
}