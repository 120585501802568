// Cards

.card-group {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -1.5%;

  .card {
    border-radius: .5em;
    margin: 20px 1.5%;
    height: 300px;
    width: 30.3%;

    .card-content, .card-reveal {
      border-radius: 0 0 2px 2px;
      padding: 24px 30px 30px 35px;

      .card-title {
        font-size: 1.5em;
        font-weight: 700;
        padding-left: 5px;
        text-transform: uppercase;
      }

      .contact-item{
        display: flex;
        font-size: 1.2em;
        margin-top: 10px;
        width: 100%;

        i{
          margin-right: 10px;
        }

        a{
          color: $dark;
        }
      }
    }
  }
}