///////
//// Sections General
/////////////////

.section-content {
  align-items: flex-start;
  display: flex;
  justify-content: center;
}

.section-box {
  font-size: 1em;
  background-color: $light;
  flex-grow: 1;
  border-radius: .5em;
  padding: 3.3em 4em;

  header {
    font-size: 2em;
    width: 100%;
    border-bottom: 1px solid $brandGrey;
    display: flex;
    align-items: center;
    color: $brandDarkBlue;
    padding-bottom: .7em;
    margin-bottom: 1.5em;

    h1 {
      font-size: 1em;
      font-weight: 700;

      &.highlight {
        color: $brandLightRed;
      }
    }

    .icon {
      margin-right: .6em;
    }
  }

  h2 {
    color: $brandDarkBlue;
    font-weight: 700;
    font-size: 1.6em;
    margin: 3em 0 1em;

    &.highlight {
      color: $brandLightRed;
    }

    &:first-child {
      margin-top: 1em;
    }
  }

  span {
    &.highlight {
      color: $brandLightRed;
    }
  }

  h3 {
    font-size: 1.3em;
    font-weight: 700;
    margin-top: 1.5em;
    margin-bottom: .3em;

    &.highlight {
      color: $brandLightRed;
    }
  }
  .collapsible-body .basic-text{
    margin-top: 2em;
  }

  .collapsible-header {
    font-size: 1.3em;
  }

  .basic-list {
    font-size: 1.3em;
  }

  .info-btn {
    display: flex;
    gap: 10px;
    align-items: center;
    margin-top: -1em;
    font-weight: 700;

    .icon {
      color: $brandLightRed;
    }
  }
}

.section-box.header-box {
  text-align: center;
  margin-bottom: 2em;

  header {
    border-bottom: none;
    justify-content: center;
    flex-direction: column;
    padding: 0;

    h1 {
      color: $brandRed;
      text-transform: uppercase;
      margin-bottom: .5em;
    }

    p {
      margin: 0;
      font-size: .7em;
    }
  }
}

#complementary-insurance {

  .highlight-black {
    font-weight: 700;
  }

  .highlight {
    color: $brandLightRed;
  }

  li {
    margin-left: 2em;
    list-style-type: disc;
  }
}

///////
//// Section Aside
/////////////////

.section-aside {
  margin-left: 2.6em;
  min-width: 20em;
  max-width: 20em;

  .aside-block {
    background-color: $brandDarkBlue;
    border-radius: .5em;
    padding: 2em 1.8em;
    margin-bottom: 1em;
    display: flex;
    align-items: center;

    img.calculadora {
      height: 6.6em;
    }

    .btn-list {
      flex-grow: 1;
      margin-left: 1em;
    }
  }

  .main-btn {
    width: 100%;
    margin-bottom: 1em;

    &:last-child {
      margin-bottom: 0;
    }
  }

  .aside-links {
    margin-top: 1em;

    .link {
      border-bottom: 1px solid $scaleGrey2;
      padding: 1.5em .5em;

      .link-btn {
        display: flex;
        align-items: center;
        width: 100%;
        //border-bottom: 1px solid $scaleGrey2;
        color: $brandDarkBlue;
        font-weight: 700;
        text-decoration: underline;
        padding: 0;
        //line-height: 0;
        background: transparent;
        box-shadow: none;
        text-transform: none;

        p {
          text-align: start;
        }

        .icon {
          color: $brandLightRed;
          margin-right: 1em;
          width: 1em;
          font-size: 2em;
        }

        &:hover {
          text-decoration: none;
        }
      }

      &:last-child {
        border-bottom: none;
      }
    }
  }
}