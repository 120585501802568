///////
//// Sucursales
/////////////////

.main-office {
  padding: 3.3em 0;

  .finaer-logo {
    display: none;
  }

  .map {
    background-color: $light;
    height: 300px;

    .map-marker {
      color: $brandLightRed;
      font-size: 3em;
      position: absolute;
      top: -60px;
      left: -40px;

      p {
        background-color: $brandLightRed;
        color: $light;
        font-size: .7em;
        border-radius: .2em;
        padding: .1em .4em;
        margin-bottom: .3em;
      }
    }
  }

  .contact-info {
    display: flex;
    flex-wrap: wrap;
    font-size: 1em;
    justify-content: center;
    padding: 3.3em 4em 0;
    width: 100%;

    .contact-item {
      display: flex;
      margin: 0 2em 1.3em;

      i {
        font-size: 1.5em;
        margin-right: .5em;
      }

      .icon-whatsapp{
        height: 1.5em;
        width: auto;
        margin-right: 5px;
        margin-top: -2px
      }

      a, p {
        color: $dark;
        margin: 0;
        font-size: 1.2em;
      }
    }
  }
}

.headquarters {
  padding-top: 3em;

  .headquarters-group {
    margin-bottom: 2.5em;

    .group-title {
      align-items: center;
      color: $brandRed;
      display: flex;
      padding-left: 16px;
      width: 100%;

      i {
        font-size: 1.8em;
        margin-right: 15px;
      }
    }

    .card-group .card {

      .card-reveal .contact-item:last-child {
        flex-wrap: wrap;
      }

      .card-image {
        align-items: center;
        display: flex;
        height: 150px;
        justify-content: center;

        img {
          max-width: 170px;
          width: 50%;
        }
      }
    }
  }
}