.landing-page-container {
  max-width: 1200px;
  width: 100%;
  margin: auto;
  position: relative;

  .-landing-main-nav {
    max-width: 1200px;
    top: 0;
    height: 90px;
    padding: 0 4em;
    z-index: 2;
    background-color: $light;
    box-shadow: 0 0 20px -15px rgba(0,0,0,0.75);
    .site-logo {
      width: 150px;
    }
  }
}

.landing-site-cover {
  position: relative;
  margin-top: 101px;
  top: -5px;
  left: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  &::before {
    content: "";
    height: 5px;
    width: 50%;
    background-color: $brandRed;
    top: -5px;
    right: 0;
    position: absolute;
  }
  &::after {
    content: "";
    height: 5px;
    width: 50%;
    background-color: $brandRed;
    bottom: -5px;
    left: 0;
    position: absolute;
  }
  .text-container {
    position: absolute;
    font-size: 18px;
    padding: 0 4em;
    p {
      font-size: 3em;
      font-weight: 500;
      text-shadow: 3px 3px 3px #ffffffa6;
      span {
        color: $brandRed;
      }
      strong {
        font-weight: 700;
      }
    }
    .primary-text {
      font-size: 4em;
      line-height: 1em;

      strong {
        font-size: 1.2em;
      }
    }

    .secondary-text {
      font-size: 1.5em;
    }

    .span-sub-text {
      font-size: 1.8rem;
      line-height: 0em;
    }

    .break-span {
      margin-bottom: 15px;
    }

    .clarify-text {
      line-height: 0em;

      .span-sub-text {
        font-size: 1.8rem;
        line-height: 0em;
      }
    }
  }

  .discount-mercadopago-container {
    .secondary-text {
      font-size: 1.2em;
    }

    .discount-span {
      font-weight: 900;
      color: #4D5B6E;
    }
  }
}

.landing-background-container {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  overflow: hidden;
  max-height: 300px;
  .landing-background {
    width: 100%;
  }
}

.mercado-libre-warranty {
  display: flex;
  align-items: center;
  height: 200px;
  justify-content: space-evenly;
  .mercado-libre-container {
    display: flex;
    align-items: center;
    p {
      margin: 0 1.2em 0 0;
      text-align: right;
      line-height: 1.2em;
    }
    .basic-text{
      font-size: 1.6em;
      span{
        color: $brandRed;
        font-weight: 500;
      }
    }
    .logo-mercado-libre {
      height: 55px;
    }
  }
  .red-card {
    background-color: $brandRed;
    width: 280px;
    height: 210px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
    padding-bottom: 1.5em;
    transform: translateY(-22px);
    border-radius: 0 0 1em 1em;
    position: relative;
    z-index: 1;

    p {
      text-align: center;
      color: $brandYellow;
      line-height: 1.3em;
      margin: .8em .5em .5em;
      font-size: 2.7em;
      span {
        color: $light;
        font-weight: 700;
      }
    }
    .main-btn {
      padding: .1em .8em;
      font-size: 1.7em;
      font-weight: 700;
    }
    .corner-vector {
      position: absolute;
      top: 0;
      right: -28px;
      height: 22px;
      transform: rotate(180deg);
      filter: grayscale(100%);
    }
  }

}

.main-footer.-landing-footer {
  padding: 2em;
  .company-info {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    .app-logo {
      width: 150px;
      margin-left: 2em;
    }
    .info {
      font-size: 1.5em;
      p {
        color: $brandYellow;
        span {
          color: $light;
          font-weight: 700;
        }
      }
      border-left: 0;
      border-right: 1px solid $brandYellow;
      padding-right: 1em;
      margin-right: 1em;
      text-align: right;
    }
  }
}

.calculator-requirement-section{
  background-color: $scaleGrey2;
  min-height: 65vh;
  display: flex;
  flex-direction: column;
  .section-box{
    background-color: inherit;
  }
}

.calculator-section {
  display: flex;
  justify-content: center;
  align-items: center;
  .section-box {
    header {
      font-size: 1.7em;
    }
  }
}

.requirements-section{
  background-color: inherit;
  align-self: center;
  width: 80%;
  min-height: 65vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 10px;
  .section-box {
    background-color: white;
    flex-grow: unset;
    padding: 2.2em 3em;
    width: 100%;
  }

  .solicitar-garatina{
    align-self: flex-end;
    margin-top: 10px;
  }
}

.options-calculator-requirements{
  border: none;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  background-color: $scaleGrey3;;
  width: inherit;
  height: 45px;
  border-radius: 10px 10px 0px 0px;

  .option {
    background-color: $scaleGrey3;
    color: black;
    font-size: 1.2em;
    font-weight: 300;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50%;
  }

  .button-text-option {
    display: flex;
    justify-content: center;
    align-items: center;
    .title-btn{
      margin-left: 15px;
    }
  }

  .right{
    border-radius: 0px 10px 0px 0px;
  }

  .left{
    border-radius: 10px 0px 0px 0px;
  }

  .active-right, .active-left{
    align-self: center;
    color: white;
    font-size: 1.4em;
    background-color: $btnColor;
    height: inherit;
    border-radius: 10px 10px 0px 0px;
  }
}

.bar-follow-warranty {
  color: black;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  line-height: 24px;

  .row-redirect-warranty{
    display: flex;
  }

  a {
    color: red;
  }

  p, a {
    font-size: 1.4em;
  }

  .short-redirect-text{
    display: none;
  }

  .long-redirect-text{
    display: block;
  }
}