// Layout Mobile

@media only screen and (max-width: 1400px) {

  .site-content {
    width: 100%;
    margin: 0;
    padding-left: 50px;
    padding-right: 50px;

  }
  .site-cover .home-background-container {
    justify-content: flex-start;
  }
}

@media only screen and (max-width: 1100px) {

  ///////
  //// Site Nav
  /////////////////

  .icon-mobile-phone {
    display: none;
  }

  .phone-number {
    display: none;
  }

  .main-nav-item {
    p {
      display: none;
    }
  }

  .bm-menu .main-nav-item {
    p {
      display: block;
    }
  }

  ///////
  //// site Footer
  ///////////////

  .main-footer {

    .app-logo {
      width: 100px;
    }
  }
}

@media only screen and (max-width: 860px) {

  ///////
  //// site Footer
  ///////////////

  .site-cover{
    .home-background-container {
      justify-content: center;
    }
    .scroll-icon {
    display: none;
  }
  .main-footer {
    flex-wrap: wrap;

    .contact-links {
      width: 100%;
      margin-top: 3em;
    }
  }
  }
}

@media only screen and (max-width: 760px) {

  .site-content {
    width: 100%;
    max-width: 450px;
    margin: 0 auto;
    padding: 0 1rem;
    flex-direction: column;
  }
  .site-cover .home-background-container {
    justify-content: flex-start;
  }

  ///////
  //// Site Nav
  /////////////////
  header.main-header {
    height: 80px;
  }

  nav.main-nav-bar {
    height: 60px;
    padding: 0 1em;

    .site-logo {
      width: 100px;
    }

    &.scroll {
      height: 60px;

      .site-logo {
        width: 100px;
      }

      .main-nav-item {
        height: 60px;
      }
    }
  }

  .main-nav-item {
    margin-left: 0;
    font-size: 1.5em;

    .icon-whatsapp{
      height: 25px;
    }
    .icon-mobile-phone{
      display: none;
    }

  }

  .main-ham-button {
    margin-left: .5em;
    font-size: 2em;
    padding: 0;
  }

  ///////
  //// site Footer
  ///////////////

  .main-footer {
    flex-direction: column;
    align-items: flex-start;
    font-size: 1.2em;
    padding: 2em 1em 3em;

    .app-logo {
      width: 120px;
      margin-bottom: 1em;
    }

    .company-info {
      flex-direction: column;
      align-items: flex-start;

      .info {
        border: none;
        margin-left: 0;
        padding-left: 0;
      }

      .data-fiscal {
        padding-left: 0;
        margin-left: 0;
      }
    }

    .footer-options {
      margin-top: 2em;
      flex-direction: column;
      align-items: flex-start;

      li {
        border-right: none;
        padding-right: 0;
        margin-right: 0;
        margin-bottom: 1em;
      }
    }

    .contact-links {
      margin-top: 1em;
    }
  }
}