// Lists

///////
//// Collapsible
///////////////

.collapsible {
  background-color: transparent;
  box-shadow: none;
  border: none;

  .collapsible-header {
    color: $brandLightRed;
    padding: 1rem .5rem;

    i {
      font-size: 1.6em;
    }
  }

  .collapsible-body {
    color: $dark;
  }

  &.-big .collapsible-header {
    font-size: 1.6em;
    //border-bottom: none;
    font-weight: 700;
  }
}

.basic-list {

  .basic-list-item {
    list-style-type: disc;
    list-style-position: inside;
    .sub-list {
      padding-left: 1.4em;
      .sub-list-item {
        list-style-type: none;
      }
    }
  }

  .doc-list-item {
    list-style-type: disc;
    list-style-position: outside;
  }
}

ul.document-list {

  li {
    list-style: disc;
    margin-left: 1em;
    &.document-accepted{
      color: $siteGreen;
      display: flex;
      font-weight: 700;
      list-style: none;
      margin-left: 0;
      .icon{
        margin-right: .5em;
      }
    }
  }
}