// Home Mobile

@media only screen and (max-width: 860px) {

  .modal {
    width: 95%;
  }

}

@media only screen and (max-width: 760px) {

  .modal {

    .modal-body {
      flex-wrap: wrap;

      .modal-main {
        width: 100%;
      }

      .modal-aside {
        min-width: 100%;
        max-width: 100%;
        margin-left: 0;
        margin-top: 3em;
      }
    }
  }
}
