///////
//// Layout Formulario
/////////////////

.layout-request.form {

  main {
    display: flex;
    align-items: stretch;
  }
}

.form-nav {
  width: 22em;
  background-color: $light;
  border-top: 1px solid $scaleGrey2;
  border-bottom: 1px solid $scaleGrey2;
  padding: 3em 1em;
  position: relative;
  button.co-solicitant-id {
    &:hover {
      color: $siteBlue;
    }
    &:focus {
      background-color: transparent;
    }
    &.-active {
      color: $siteBlue;
      font-size: 1.2em;
      font-weight: 700;
      max-height: inherit;
      transition: font-size .3s;
    }
  }
}

.form-status-container {
  align-items: center;
  display: flex;
  height: 2em;
  justify-content: space-between;
  position: absolute;
  top: 1em;
  width: 800px;
}

.form-body {
  flex-grow: 1;
  display: flex;
  align-items: center;
  padding-top: 6em;
  flex-direction: column;

  .form-content {
    width: 900px;
    height: 470px;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 0 6em;

    .form-inputs {
      animation-name: appear;
      animation-duration: 1s;
      animation-fill-mode: forwards;
      height: 450px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding-top: 3em;
      &.documents-input {
        height: auto;
      }
    }
    .document-type-select input.select-dropdown {
      color: $siteBlue;
      font-size: 1.3em;
      font-weight: 700;
    }

    .direction-btn {
      position: absolute;
      top: 45%;

      &.-left {
        left: 0;
      }

      &.-right {
        right: 0;
      }
    }

    .slider-nav {
      display: flex;
      align-items: center;
      bottom: 0;
      justify-content: center;
      width: 100%;
      margin-top: 25px;

      .circle {
        font-size: .8em;
        color: $scaleGrey3;
        margin: 0 2em;

        &.-active {
          color: $siteBlue;
          animation-name: grow;
          animation-duration: .5s;
          animation-fill-mode: forwards;
        }
      }
    }
  }

  .form-header {
    font-size: 1.7em;
    color: $brandDarkBlue;
    font-weight: 700;
    top: 0;
  }

  .form-subtitle {
    font-size: 1em;
    color: $siteBlue;
    font-weight: 400;
    text-transform: uppercase;
  }
}

.form-nav-item {
  width: 100%;
  padding: 0 1em;
  height: 5em;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: .4em;

  p {
    color: $brandGrey;
    font-size: 1.2em;
    font-weight: 700;
  }

  &:hover {

    p {
      color: $siteBlue;
    }
  }

  &:focus {
    background: transparent;
  }

  &.-active {
    background-color: $brandDarkBlue;

    p {
      color: $light;
    }

    .section-progressbar {

      .CircularProgressbar-trail {
        stroke: darken($brandDarkBlue, 10%);
      }
    }
  }
}

.collapsible-item-container {
  border-radius: .4em;
  overflow: hidden;
  position: relative;
  .CircularProgressbar {
    position: absolute;
    right: 1em;
    top: 1em;
  }
  &.-active {
    background-color: $brandDarkBlue;
    .section-progressbar .CircularProgressbar-trail {
      stroke: #384250;
    }
    .collapsible-item .collapsible-header {
      background-color: inherit;
      border: none;
      color: $light;
      height: 4em;
      display: flex;
      align-items: center;
      padding: 1.6rem 1em 1.4em;
      font-size: 1.2em;
      font-weight: 700;
      &:hover {
        color: $light;
      }
    }
    .collapsible-body {
      background-color: $scaleGrey1;
      border-bottom: none;
      padding: 1.5em 1em;
      ul li {
        padding: .5em 0;
        &:first-child {
          padding-top: 0;
        }
        &:last-child {
          padding-bottom: 1em;
        }
        a {
          color: $brandGrey;
          font-size: 1.1em;
          font-weight: 700;
        }
      }
    }
  }
  .collapsible-item {
    color: $brandGrey;
    cursor: pointer;
    margin: 0;
    width: 100%;

    .collapsible-header {
      color: $brandGrey;
      border: none;
      font-size: 1.2em;
      font-weight: 700;
      padding: 1.6rem 1em 1.4em;
      &:hover {
        color: $siteBlue;
      }
    }
  }

}

.form-nav-info {
  bottom: 0;
  padding: 0 1em 2em 1em;
  position: absolute;
  .request-code {
    margin-bottom: 3em;
    .code {
      font-size: 2em;
      line-height: 1.2em;
    }
  }
  .assigned-office-info {
    .info-title {
      font-weight: 700;
    }
    a {
      color: $dark;
      width: 100%;
    }
  }
  .-highlight-info{
    animation: text-grow 2s;
    animation-timing-function: ease-in-out;
    a{
      animation: text-grow 2s;
      animation-timing-function: ease-in-out;
    }
  }
}



.section-progressbar {
  width: 3em;

  .CircularProgressbar-trail {
    stroke: $scaleGrey2;
  }

  .CircularProgressbar-path {
    stroke: $siteBlue;
  }

  .CircularProgressbar-text {
    fill: $siteBlue;
    font-weight: 700;
    font-size: 1.6em;
  }
}

.inactive .collapsible-body {
  display: none !important;
}

.title-checked-container {
  display: flex;
  align-items: center;
}

.form-disabled-warning {
  @extend .warning-text;
  color: $brandRed;
  font-weight: 700;
  font-size: 1.2em;
}