// Buttons

button, input[type=submit] {
  border: none;
  background: transparent;
  display: inline-block;
}

a {
  background: transparent;
  display: inline-block;

  &:focus {
    background-color: transparent;
    outline: 0;
  }
}

.main-ham-button {
  color: $brandRed;
  font-size: 1.7em;
  margin-left: 1.5em;

  &:focus {
    background: transparent;
  }
}

.main-btn {
  font-size: 1em;
  color: $light;
  background-color: $btnColor;
  border-radius: .4em;
  text-align: center;
  padding: .5em 1.7em;
  box-shadow: 12px 12px 20px 0 rgba(0, 0, 0, 0.1);
  transition: background-color, box-shadow .3s;

  &:hover {
    background-color: $btnHoverColor;
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  }

  &:focus {
    background-color: $btnHoverColor;
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  }

  &.fixed-width {
    width: 100%;
  }
}


.secondary-btn {
  font-size: 1em;
  color: $light;
  background-color:  $scaleGrey2;
  border-radius: .4em;
  text-align: center;
  padding: .5em 1.7em;
  box-shadow: 12px 12px 20px 0 rgba(0, 0, 0, 0.1);
  transition: background-color, box-shadow .3s;

  &:hover {
    background-color: $btnHoverColor;
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  }

  &:focus {
    background-color: $btnHoverColor;
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  }

  &.fixed-width {
    width: 100%;
  }
}

button.main-btn:disabled, button.main-btn[disabled] {
  background-color: $scaleGrey2;
  color: $light;
  box-shadow: none;
}

.round-btn {
  font-size: 1em;
  height: 3em;
  line-height: 3em;
  border-radius: 1.5em;
  color: $light;
  background-color: $btnColor;
  text-align: center;
  padding: 0 2.5em;
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.10);
  transition: background-color, box-shadow .3s;

  &:hover {
    background-color: $btnHoverColor;
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  }

  &:focus {
    background-color: $btnHoverColor;
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  }

  &.fixed-width {
    width: 100%;
  }
}

.direction-btn {
  font-size: 1em;
  height: 3em;
  width: 3em;
  background-color: $light;
  border-radius: 50%;
  color: $scaleGrey3;
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.10);
  transition: color, box-shadow .3s;

  &:hover {
    color: $btnColor;
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  }

  &:focus {
    background-color: $light;
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  }

  &-left {
    margin-right: 1em;
  }

  &-right {
    margin-left: 1em;
  }
}

.logout-btn {
  color: $scaleGrey3;
  font-size: 1.2em;
  display: flex;
  align-items: center;
  font-weight: 700;
  padding: 0;
  transition: color .3s;

  &:hover {
    color: $brandLightRed;
  }

  &:focus {
    background: transparent;
  }

  .icon {
    font-size: 1.2em;
    margin-left: .3em;
  }
}

.state-btn {
  color: $btnColor;
  text-transform: uppercase;
  text-decoration: underline;
  font-weight: 700;
  margin-left: 4px;
  &:hover {
    text-decoration: none;
  }
  &:focus{
    background-color: $btnHoverColor;
    border-radius: .5em;
    color: white;
  }

  .icon {
    margin-left: .5em;
  }
}

.dashboard-nav-btn {
  font-size: 1.1em;
  font-weight: 700;
  color: $brandDarkBlue;
  width: 100%;
  display: flex;
  align-items: center;
  padding: 1em .5em;
  border-bottom: 1px solid $brandGrey;

  &:last-child {
    border-bottom: none;
  }

  p {
    flex-grow: 1;
  }

  .icon {
    margin-right: 1em;
  }

  .caret {
    opacity: 0;
    transition: opacity .2s;
  }

  &:hover {

    .caret {
      opacity: 1;
    }
  }
}

.back-btn {
  padding: 0;
  transition: color .3s;
  color: $dark;

  &:before {
    content: '<< ';
  }

  &:hover {
    color: $siteBlue;
  }

  &:focus {
    background: transparent;
  }
}

.styless-btn {
  &:focus {
    background: transparent;
  }
}