///////
//// Formulario de Solicitud // MOBILE
/////////////////

.layout-request.form.-mobile {

  main {
    display: block;
    position: relative;
  }
}

.form-nav-mobile {
  background-color: $light;
  padding: 3em 1em;
  width: 100%;
  position: absolute;
  bottom: 0;
  z-index: 999;
  display: flex;
  justify-content: center;
  align-items: center;
}

.form-nav-item-mobile {
  padding: 0 1em;
  height: 5em;
  width: 5em;
  cursor: pointer;

  svg {
    color: $scaleGrey3;
  }

  &:hover {

    p {
      color: $siteBlue;
    }
  }

  &:focus {
    background: transparent;
  }
}

@media only screen and (max-width: 1350px) {
  .form-body .form-content {
    width: 800px;
    padding: 0 4em;
  }

}

@media only screen and (max-width: 1200px) {

  .form-body {
    padding-top: 8em;
    position: relative;
    .form-header {
      position: static;
    }
    .form-content {
      .content-radio-buttons {
        margin-top: 2.5rem;
      }
      .form-inputs {
        height: inherit;
        margin-bottom: 3em;

      }
    }
  }

  .form-status-container {
    background-color: $scaleGrey1;
    border-bottom: 1px solid $scaleGrey2;
    margin-top: 5em;
    padding: 2em;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 1;
  }

  .spinner-save-container {
    align-items: center;
    display: flex;
    justify-content: center;
    p {
      font-size: 1.1em;
      margin-right: 0;
    }
    .spinner-save {
      animation: none;
      margin-right: -1em;
      transform: scale(0.5);
      transition: none;
    }
  }

  .form-inputs .combined-input.col.s12 .col.input-field.s2{
    width: 25%;
  }

  //////// form menu mobile

  button.form-mobile-close {
    display: block;
    padding: 0;
    margin: 0;
    &:focus {
      background-color: transparent;
    }
    .icon {
      cursor: pointer;
      display: block;
      font-size: 1.3em;
      position: absolute;
      right: 1em;
      top: .6em;
    }
  }

  .form-nav {
    border: none;
    .collapsible-item-container {
      .main-btn {
        padding: .5em 0;
        width: 100%;
      }
    }
    &.-mobile-menu {
      .logout-btn {
        display: flex;
        position: absolute;
        top: .5em;
      }
    }
  }

  .section-progressbar .CircularProgressbar-text {
    font-size: 2.4em;
  }

  .form-mobile-open {
    display: block;
    padding: 0;
    line-height: 0;
    &:focus {
      background-color: transparent;
    }
    svg {
      fill: $scaleGrey3;
      font-size: 1.4em;
    }
  }

  .-mobile .request-nav-bar {
    z-index: 2;
  }

  .-mobile main .back-btn {
    font-size: 1.2em;
    margin: 0;
  }

  .title-checked-container {
    justify-content: center;
  }

}

@media only screen and (max-width: 450px) {
  .-mobile main .form-body-container .form-body .form-content {
    width: 100%;
  }
}

@media only screen and (max-width: 425px) {
  .form-status-container {
    top: -.5em;
  }
}